import { useState } from "react";
import { useParams } from "react-router-dom";
import listJobs from "../listJobs";
import "./Details.scss";
import Linkedin from "../../../assets/LinkedIn.svg";
import { Link } from "react-router-dom";
import ArrowRigth from "../../../assets/card-icon/arrow-right.svg"

export const Details: React.FC<{}> = () => {
  //obtener el id del job
  const { id } = useParams();
  const job = listJobs.find((job) => job.id === id);

  //un state que solo tome los ultimos 3 de la lista
  const [lastsJobs, setListJobs] = useState(listJobs.slice(0, 3));

  const handleClickShare = () => {
    window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=" +
        window.location.href
    );
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="container-information">
      <div className="section-title">
        <h3 className="subtitle-job">EMPLEOS</h3>
        <h1 className="title-job">{job?.position}</h1>
        <div className="date-location">
          <h3 className="date">{job?.date}</h3>
          <h3 className="location">{job?.location}</h3>
        </div>
        <div className="section-buttons">
          <button className="btn-apply">APLICAR</button>
          <button className="btn-share" onClick={handleClickShare}>
            COMPARTIR
            <img src={Linkedin} alt="linkedin" />
          </button>
        </div>
      </div>
      <div className="section-details-jobs">
        <div className="section-description">
          <div className="info-details">
            <h3 className="acerca-de">Acerca del empleo</h3>
            {job?.responsabilities?.map((responsibility) => (
              <p key={responsibility}>{responsibility}</p>
            ))}
          </div>
        </div>
        <div className="section-details">
          <div className="challenges">
            <h3 className="title-challenges">Algunos De Tus Desafíos</h3>
            <ul>
              {job?.challenges?.map((challenge) => (
                <li key={challenge}>{challenge}</li>
              ))}
            </ul>
          </div>

          <div className="requirements">
            <h3 className="title-requirements">Requisitos</h3>
            <ul>
              {job?.requirements?.map((requirement) => (
                <li key={requirement}>{requirement}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="similar-jobs">
        <h3 className="title-similar-jobs">Empleos similares</h3>
        <div className="cards">
          {lastsJobs.map((job) => (
            <div className="card" key={job.id}>
              <div className="card-city">
                <h3 className="city">{job.city}</h3>
                {/* <h3 className="location">{job.location}</h3> */}
              </div>
              <div className="card-title">
                <h3 className="position">{job.position}</h3>
                <p className="location">{job.description}</p>
              </div>
              <div className="card-footer-link">
                <Link to={`/job/${job.id}`} onClick={handleScrollTop}>
                  Ver Detalle
                  <img className='img-arrow' src={ArrowRigth} />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
