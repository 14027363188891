import Ilustracion from "../../assets/salesforce/Ilustracion.svg";
import Trabajo from "../../assets/salesforce/Trabajo.svg";
import Challenge from "../../assets/salesforce/Challenge.svg";
import Scrum from "../../assets/salesforce/Scrum.svg";

const business = [
    {
        "id": 1,
        "description": `¿Cuál es la visión de tu negocio y cómo se encuentra hoy? Desde
        allí partimos para abordar la transformación digital con la
        tecnología confiable, flexible y escalable de Salesforce.`,
        "image": Challenge
    },
    {
        "id": 2,
        "description": `Trabajamos con empresas de todos los tamaños y con procesos
        definidos para cada segmento en particular.`,
        "image": Ilustracion
    },
    {
        "id": 3,
        "description": `Mejoramos la experiencia de tus clientes y garantizamos la
        productividad de las distintas áreas al conectar marketing,
        ventas, operaciones, servicio al cliente y IT.`,
        "image": Trabajo
    },
    {
        "id": 4,
        "description": `Como empleamos metodologías ágiles, nuestros clientes comienzan
        a ver resultados rápidamente.`,
        "image": Scrum
    }
]

export default business;