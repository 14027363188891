import { Header } from "../components/Header/Header";
import { Footer } from '../components/Footer/Footer';
import { SoftwareFactory } from "../components/Software/SoftwareFactory";
import { Contact } from "../components/Contact/Contact";

export const Software: React.FC<{}> = () => {
    return (
        <>
            <Header />
            <SoftwareFactory />
            <Contact/>
            <Footer />
        </>
    );
};