import Talento from "../../assets/about/Talento.svg";
import Potencia from "../../assets/about/Potencia.svg";
import Equipo from "../../assets/about/Equipo.svg";
import Mentalidad from "../../assets/about/Mentalidad.svg";

const data = [{
        id: 1,
        image: Talento,
        title: 'Creación y coordinación de equipos de ventas',
        information: `Nuestro equipo combina ingenieros en informática y estudiantes
        próximos a graduarse, asegurando un proceso de desarrollo de
        software actualizado y de calidad.`
    },
    {
        id: 2,
        image: Potencia,
        title: 'Potencia de ingeniería',
        information: `Tenemos las habilidades necesarias para construir productos
        digitales completos, incluyendo desarrollo web y móvil, control
        de calidad y pruebas, big data y DevOps.`
    },
    {
        id: 3,
        image: Equipo,
        title: 'Gran trabajo de equipo',
        information: `Nos integramos como parte de tu equipo en el sitio, siempre
        disponibles y en sincronía para reuniones, revisiones de código
        y decisiones importantes.`
    },
    {
        id: 4,
        image: Mentalidad,
        title: 'Mentalidad Startup',
        information: `Nos encanta participar de proyectos complejos con tecnología
        avanzada y equipos sofisticados, garantizando el éxito. Contamos
        con agilidad y actualización para cumplir tus expectativas.`
    }
]

export default data;