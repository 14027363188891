import React, { useEffect } from "react";
import "./Site.scss";
import Rocket from "../../assets/rocket.svg";
import Arrow from "../../assets/arrow.svg";
import NIcon from "../../assets/n-icon.svg";
import DownArrow from "../../assets/down-arrow.svg";
import DownArrowHover from "../../assets/down-arrow-hover.svg";
import SalesForce from "../../assets/salesforce.svg";
import M from "../../assets/m.svg";
import AmericanExpress from "../../assets/americanexpress.svg";
import Swal from "sweetalert2";
import MsgSuccess from "../../assets/message-success.svg";
import MsgRejected from "../../assets/message-rejected.svg";

export const Site: React.FC<{}> = () => {
  const scrollToSection2 = () => {
    const section2 = document.getElementById("section2");
    section2?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const formSuccess = urlParams.get("form_success");

    if (formSuccess === "true") {
      Swal.fire({
        imageUrl: MsgSuccess,
        text: "¡Mensaje enviado! Nos pondremos en contacto lo antes posible.",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
      });
    } else if (urlParams.toString() !== "") {
      Swal.fire({
        imageUrl: MsgRejected,
        html: 'En este momento no podemos enviar el mensaje, intenta nuevamente en unos minutos o escríbenos a <span class="email">mail@mail.com</span>',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
      });
    }
  }, []);

  return (
    <div className="site-container">
      <div className="home-contianer">
        <button className="down-button" onClick={scrollToSection2}>
          <img className="down-arrow normal" src={DownArrow} />
          <img className="down-arrow hover" src={DownArrowHover} />
        </button>
        <div className="title-contianer-desktop">
          <div className="title-row">
            <p className="title">
              Asegura la transformación digital de tu negocio.
              <span className="icon-container">
                <img src={Rocket} />
              </span>
            </p>
          </div>

          <div className="sub-title-container">
            <p className="sub-title">
              Llega más lejos gracias a nuestros desarrollos tecnológicos y
              equipos interdisciplinarios de especialistas.
            </p>
            <img className="n-icon" src={NIcon} />
            <button className="contact-button">
              CONTACTATE <img src={Arrow} />
            </button>
          </div>
        </div>
        <div className="title-contianer-mobile">
          <div className="title-row">
            <p className="title">
              Asegura la transformación digital de tu negocio.
              <span className="icon-container">
                <img src={Rocket} />
              </span>
            </p>
          </div>

          <div className="sub-title-container">
            <p className="sub-title">
              Llega más lejos gracias a nuestros desarrollos tecnológicos y
              equipos interdisciplinarios de especialistas.
            </p>
            <img className="n-icon" src={NIcon} />
            <button className="contact-button">
              CONTACTATE <img src={Arrow} />
            </button>
          </div>
        </div>

        <div className="footer-container-mobile">
          <p>Trusted Partners</p>
          <img src={SalesForce} />
          <img src={M} />
          <img src={AmericanExpress} />
        </div>
      </div>
      <div className="footer-container">
        <p>Trusted Partners</p>
        <img src={SalesForce} />
        <img src={M} />
        <img src={AmericanExpress} />
      </div>
    </div>
  );
};
