import { useState } from "react";
import imageJob from "../../assets/ImagenJob.svg";
import "./Work.scss";
import { Link } from "react-router-dom";
import listJobs from "./listJobs";
import select from "./select";
import SendArrow from '../../assets/send-arrow.svg'
import ArrowLeft from "../../assets/ArrowLeft.svg";
import { FloatingLabel, Form } from "react-bootstrap";

export const Work: React.FC<{}> = () => {
  const [value, setValue] = useState("");
  const [jobs, setJobs] = useState(listJobs.slice(0, 5));
  const [visibleJobs, setVisibleJobs] = useState(5);

  const handleLoadMore = () => {
    setVisibleJobs((prevVisibleJobs) => prevVisibleJobs + 5);
    setJobs(listJobs.slice(0, visibleJobs + 5));
  };

  const handleValue = (e: any) => {
    setValue(e.target.value);
    e.target.classList.add("selected");
    select.map((select) => {
      if (select.position !== e.target.value) {
        const btn = document.querySelector(
          `button[value="${select.position}"]`
        );
        btn?.classList.remove("selected");
      }
    });
  };


  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="container-information">
      <div className="section-title">
        <h3 className="subtitle-job">EMPLEOS</h3>
        <h1 className="title-job">
          Únete a nuestro equipo, da el primer paso hacia una carrera increíble.
        </h1>
        <div className="image-work">
          <img src={imageJob} />
        </div>
      </div>

      <div className="section-list-jobs">
        {jobs.map((job) => (
          <div key={job.id}>
            <div className="section-description">
              <div className="info">
                <h3 className="date">{job.date}</h3>
                <div className="description">
                  <h1 className="title">{job.position}</h1>
                  <h3 className="location">{job.location}</h3>
                </div>
              </div>
              <div className="link-job">
                <Link to={`/job/${job.id}`} onClick={handleScrollTop}>
                  <img className="arrow-left" src={ArrowLeft} />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="section-more">
        {visibleJobs < listJobs.length && (
          <button className="button-more" onClick={handleLoadMore}>
            Ver más
          </button>
        )}
      </div>

      <div className="form-contact">
        <h1 className="title-contact">¿No hay ofertas que te interesen?</h1>
        <h3 className="subtitle-contact">
          Si ninguna de las búsquedas se adapta a tu perfil, déjanos tus datos y
          te tendremos en cuenta para futuras búsquedas.
        </h3>
        <div className="select">
          <h3 className="text-select">Selecciona tu perfil</h3>
          <div className="option-select">
            {select.map((select) => (
              <div className="select-option" key={select.id}>
                <button
                  className="btn btn-option"
                  onClick={handleValue}
                  value={select.position}
                >
                  {select.position}
                </button>
              </div>
            ))}
          </div>
        </div>

        <form
          className="section-form"
          // onSubmit={(e) => handleSubmit(e)}
          // onFocus={deleteError}
        >
          <div className="form-group">
            <div className="form-group-row">
              <FloatingLabel
                controlId="floatingInput"
                label="Nombre*"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Nombre*"
                  // value={contact.name}
                  name="name"
                  // onChange={handleChange}
                  // {...(errors.name && { className: "error" })}
                />

                {/* {errors.name && <p className="error-text">{errors.name}</p>} */}
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Correo electrónico*"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="business"
                  // value={contact.business}
                  placeholder="Correo electrónico*"
                  // onChange={handleChange}
                  // {...(errors.business && { className: "error" })}
                />
                {/* {errors.business && (
                <p className="error-text">{errors.business}</p>
              )} */}
              </FloatingLabel>
            </div>
            <div className="form-group-row">
              <FloatingLabel
                controlId="floatingInput"
                label="Linkedin"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="Linkedin"
                  // value={contact.position}
                  placeholder="Linkedin"
                  // onChange={handleChange}
                  // {...(errors.position && { className: "error" })}
                />
                {/* {errors.position && (
                <p className="error-text">{errors.position}</p>
              )} */}
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Portfolio"
                className="mb-3"
                // {...(errorEmail && { className: "error-text-email" })}
              >
                <Form.Control
                  type="text"
                  name="Portfolio"
                  // value={contact.email}
                  placeholder="Portfolio"
                  // onChange={handleChange}
                  // {...(errors.email && { className: "error" })}
                  // {...(errorEmail && { className: "errorEmail" })}
                  // onFocus={() => setErrorEmail(false)}
                />

                {/* {errorEmail && (
                <div className="error-text">
                  <p>Por favor, ingresa un e-mail válido.</p>
                </div>
              )}
              {errors.email && <p className="error-text">{errors.email}</p>} */}
              </FloatingLabel>
            </div>
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Mensaje*"
              className="mb-3"
            >
              <Form.Control
                as="textarea"
                name="message"
                // value={contact.message}
                placeholder="Mensaje*"
                style={{ height: "150px" }}
                // onChange={handleChange}
                // {...(errors.message && { className: "error" })}
              />
              {/* {errors.message && <p className="error-text">{errors.message}</p>} */}
            </FloatingLabel>

            <button className="send-button" type="submit">
              ENVIAR DATOS <img src={SendArrow} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
