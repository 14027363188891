const select = [{
        "id": 1,
        "position": "Desarrollador Frontend",
    },
    {
        "id": 2,
        "position": "Team Leader Frontend",
    },
    {
        "id": 3,
        "position": "Desarrollador Backend",
    },
    {
        "id": 4,
        "position": "Team Leader Backend",
    },
    {
        "id": 5,
        "position": "Product Manager",
    },
    {
        "id": 6,
        "position": "Technical Leader",
    },
    {
        "id": 7,
        "position": "UX/UI Designer",
    },
    {
        "id": 8,
        "position": "UX Writer",
    },
    {
        "id": 9,
        "position": "Salesforce Specialist",
    },
    {
        "id": 10,
        "position": "Marketing Specialist",
    },
    {
        "id": 11,
        "position": "Otro",
    }
]

export default select;