import dedicado from '../../assets/salesforce/dedicado.svg';
import soporte from '../../assets/salesforce/soporte.svg';
import implementacion from '../../assets/salesforce/implementacion.svg';

const services = [
    {
        "id": 1,
        "name": "Equipo dedicado",
        "description": `ProContacto provee todos los roles que necesitan para completar el equipo que requieres. Generalmente están compuestos por PM, SW engineers, QA engineers y otros roles definidos específicamente en cada proyecto.
        Un equipo dedicado trabaja de forma autónoma pero comunica y reporta estados con la frecuencia que se solicite.`,
        "image": dedicado
    },
    {
        "id": 2,
        "name": "Soporte",
        "description": `ProContacto provee todos los roles que necesitan para completar el equipo que requieres. Generalmente están compuestos por PM, SW engineers, QA engineers y otros roles definidos específicamente en cada proyecto.
        Un equipo dedicado trabaja de forma autónoma pero comunica y reporta estados con la frecuencia que se solicite.`,
        "image": soporte
    },
    {
        "id": 3,
        "name": "Implemantación",
        "description": `ProContacto provee todos los roles que necesitan para completar el equipo que requieres. Generalmente están compuestos por PM, SW engineers, QA engineers y otros roles definidos específicamente en cada proyecto.  
        Un equipo dedicado trabaja de forma autónoma pero comunica y reporta estados con la frecuencia que se solicite.`,
        "image": implementacion
    }
]

export default services;