import ProContacto from "../../assets/ProcontactoLogo.svg";
import LinkedInk from "../../assets/lk.svg";
import Phone from "../../assets/Phone.svg";
import Location from "../../assets/Location.svg";
import "./Footer.scss";

export const Footer: React.FC<{}> = () => {
  return (
    <footer className="container-footer">
      <div className="container-logos">
        <div className="container-imgProcontacto">
          <img src={ProContacto} />
        </div>
        <div className="container-linkedin">
          <a href="https://www.linkedin.com/company/grupoprocontacto/" target="_blank" >
            <img src={LinkedInk} />
          </a>
        </div>
      </div>
      <div className="container-contact-footer">
        <div className="container-detail-contact">
          <div className="info-contact-footer">
            <img src={Phone} />
            <p className="tel">+52 55 3626 7522</p>
          </div>

          <div className="info-contact-footer">
            <img src={Location} />
            <p className="direccion">Av. Revolución 779, Ciudad de México.</p>
          </div>
        </div>
        <div className="container-terminos">
          <a className="terminos">Terms</a>
          <a  href='/privacy-policy' className="terminos">Privacy</a>
          <a className="terminos">Cookies</a>
          <a className="terminos-copy">© ProContacto 2022</a>

        </div>
      </div>
    </footer>
  );
};
  