import { useState } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import "../InfoServices/InformationServices.scss";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import Job from "../../assets/marketing/Job.svg";
import Service from "../../assets/marketing/Service.svg";
import Business from "../../assets/marketing/Business.svg";
import Hands from "../../assets/marketing/Hands.svg";
import Briefcase from "../../assets/marketing/Briefcase.svg";
import Handset from "../../assets/marketing/Handset.svg";
import ArrowRightDisable from "../../assets/ArrowRightDisable.svg";
import ArrowLeftDisable from "../../assets/ArrowRightDisable.svg";
import ArrowRight from "../../assets/ArrowRight.svg";
import ArrowLeft from "../../assets/ArrowLeft.svg";
import {dataSales, dataService, dataOther} from  './data'


export const CarouselDesktop: React.FC<{}> = () => {
  const [ventas, setVentas] = useState<Boolean>(true);
  const [service, setService] = useState<Boolean>(false);
  const [other, setOther] = useState<Boolean>(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [disableArrowLeft, setDisableArrowLeft] = useState<Boolean>(true);
  const [disableArrowRight, setDisableArrowRight] = useState<Boolean>(false);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const handleClickVentas = () => {
    const sliderVentas = document.querySelector(".slider-sales");
    const sliderService = document.querySelector(".slider-service");
    const sliderOther = document.querySelector(".slider-other");
    const buttonSales = document.querySelector(".button-sales");
    const buttonService = document.querySelector(".button-service");
    const buttonOther = document.querySelector(".button-other");

    sliderVentas?.classList.remove("hidden");
    sliderService?.classList.add("hidden");
    sliderOther?.classList.add("hidden");

    buttonSales?.classList.add("button-select");
    buttonService?.classList.remove("button-select");
    buttonOther?.classList.remove("button-select");

    setVentas(true);
    setService(false);
    setOther(false);
  };

  const handleClickService = () => {
    const sliderService = document.querySelector(".slider-service");
    const sliderVentas = document.querySelector(".slider-sales");
    const sliderOther = document.querySelector(".slider-other");
    const buttonSales = document.querySelector(".button-sales");
    const buttonService = document.querySelector(".button-service");
    const buttonOther = document.querySelector(".button-other");

    sliderService?.classList.remove("hidden");
    sliderVentas?.classList.add("hidden");
    sliderOther?.classList.add("hidden");

    buttonService?.classList.add("button-select");
    buttonSales?.classList.remove("button-select");
    buttonOther?.classList.remove("button-select");

    setVentas(false);
    setOther(false);
    setService(true);
  };

  const handleClickOther = () => {
    const sliderService = document.querySelector(".slider-service");
    const sliderVentas = document.querySelector(".slider-sales");
    const sliderOther = document.querySelector(".slider-other");
    const buttonSales = document.querySelector(".button-sales");
    const buttonService = document.querySelector(".button-service");
    const buttonOther = document.querySelector(".button-other");

    sliderOther?.classList.remove("hidden");
    sliderService?.classList.add("hidden");
    sliderVentas?.classList.add("hidden");

    buttonOther?.classList.add("button-select");
    buttonService?.classList.remove("button-select");
    buttonSales?.classList.remove("button-select");

    setVentas(false);
    setService(false);
    setOther(true);
  };

  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={10}
        naturalSlideHeight={
          width < 900 ? 18 : width < 1100 ? 14 : width < 1300 ? 10 : 8
        }
        totalSlides={ventas ? 6 : service ? 5 : 7}
        visibleSlides={3}
      >
        <div className="sectionCarousel" id="sectionCarousel">
          <div className="containerCarousel">
            <div className="container-button">
              <button
                className="button-sales button-select"
                onClick={handleClickVentas}
              >
                Fuerza de ventas
              </button>
              <button className="button-service" onClick={handleClickService}>
                Visitas de servicio
              </button>
              <button className="button-other" onClick={handleClickOther}>
                Otros servicios
              </button>
            </div>
            <div className="container-arrow">
              <ButtonBack>
                <img className="arrow-right" src={ArrowRight} />
              </ButtonBack>
              <ButtonNext>
                <img className="arrow-left" src={ArrowLeft} />
              </ButtonNext>
            </div>
          </div>
        </div>
        <div className="section-sliders">
          <Slider className="slider-sales">
            {dataSales.map((item, index) => (
              <Slide index={index} key={item.id}>
                <div className="container-slide">
                  <div className="container-image">
                    <img src={item.image} />
                  </div>
                  <div className="container-text">
                    <h3 className="title-slide">
                      {item.title}
                    </h3>
                    <p className="text-slide">
                      {item.text}
                    </p>
                  </div>
                </div>
              </Slide>
            ))}
          </Slider>

          <Slider className="slider-service hidden">
            <Slide index={0}>
              <div className="container-slide">
                <div className="container-image">
                  <img src={Job} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Encuestas y evaluaciones de servicio presenciales
                  </h3>
                  <p className="text-slide">
                    Visitamos los negocios afiliados para relevar cómo están
                    utilizando tus servicios.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide index={1}>
              <div className="container-slide">
                <div className="container-image">
                  <img src={Service} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Soporte primer nivel a terminales punto de venta
                  </h3>
                  <p className="text-slide">
                    Nuestro equipo de visitas se encarga del primer nivel de
                    soporte a los negocios.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide index={2}>
              <div className="container-slide">
                <div className="container-image">
                  <img src={Business} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Señalización de comercios con material POP
                  </h3>
                  <p className="text-slide">
                    Señalizamos los comercios con diferentes materiales de tu
                    marca.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide index={3}>
              <div className="container-slide">
                <div className="container-image">
                  <img src={Hands} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Generación de prospectos en campo
                  </h3>
                  <p className="text-slide">
                    Relevamos candidatos para subir la eficiencia de tu equipo
                    de ventas.
                  </p>
                </div>
              </div>
            </Slide>
            <Slide index={4}>
              <div className="container-slide">
                <div className="container-image">
                  <img src={Briefcase} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Entrega de rollos para terminales punto de venta
                  </h3>
                  <p className="text-slide">
                    Abastecemos de insumos a los comercios adheridos.
                  </p>
                </div>
              </div>
            </Slide>
          </Slider>

          <Slider className="slider-other hidden">
            <Slide index={0}>
              <div className="container-slide-other">
                <div className="container-image">
                  <img src={Job} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Auditoría de productos y servicios en punto de venta
                  </h3>
                </div>
              </div>
            </Slide>
            <Slide index={1}>
              <div className="container-slide-other">
                <div className="container-image">
                  <img src={Service} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">Mesas de ayuda</h3>
                </div>
              </div>
            </Slide>
            <Slide index={2}>
              <div className="container-slide-other">
                <div className="container-image">
                  <img src={Business} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">Soporte y atención al cliente</h3>
                </div>
              </div>
            </Slide>
            <Slide index={3}>
              <div className="container-slide-other">
                <div className="container-image">
                  <img src={Hands} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">Campañas telemarketing</h3>
                </div>
              </div>
            </Slide>
            <Slide index={4}>
              <div className="container-slide-other">
                <div className="container-image">
                  <img src={Briefcase} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Cierre de ventas con cargo a tarjetas de crédito y débito
                  </h3>
                </div>
              </div>
            </Slide>
            <Slide index={5}>
              <div className="container-slide-other">
                <div className="container-image">
                  <img src={Briefcase} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Geolocalización de los puntos de venta.
                  </h3>
                </div>
              </div>
            </Slide>
            <Slide index={6}>
              <div className="container-slide-other">
                <div className="container-image">
                  <img src={Briefcase} />
                </div>
                <div className="container-text">
                  <h3 className="title-slide">
                    Instalación y recuperación de terminales punto de venta
                  </h3>
                </div>
              </div>
            </Slide>
          </Slider>
        </div>
      </CarouselProvider>
    </div>
  );
};
