import { useState } from "react";
import Marquee from "react-fast-marquee";
import "./Experience.scss";
import Pickit from "../../assets/xp/pickit.svg";
import Ablblev from "../../assets/xp/ablnbev.svg";
import Calipso from "../../assets/xp/calipso.svg";
import Castia from "../../assets/xp/castia.svg";
import Celeritech from "../../assets/xp/celeritech.svg";
import ClearChanel from "../../assets/xp/clear-chanel.svg";
import Elans from "../../assets/xp/elans.svg";
import Fr8hub from "../../assets/xp/fr8hub.svg";
import huawei from "../../assets/xp/huawei.svg";
import Karun from "../../assets/xp/karun.svg";
import Kutshki from "../../assets/xp/kushki.svg";
import Lexus from "../../assets/xp/lexus.svg";
import Quimitroniac from "../../assets/xp/quimitroniac.svg";
import Scalabon from "../../assets/xp/scalabon.svg";
import SegundaMano from "../../assets/xp/segundamano.svg";
import Simens from "../../assets/xp/simens.svg";
import Skania from "../../assets/xp/skania.svg";
import Skydropx from "../../assets/xp/skydropx.svg";
import Teka from "../../assets/xp/teka.svg";
import Toyota from "../../assets/xp/toyota.svg";
import Unisef from "../../assets/xp/unilever.svg";
import Ventup from "../../assets/xp/ventup.svg";
import wildbrands from "../../assets/xp/wildbrands.svg";
import yamaha from "../../assets/xp/yamaha.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export const Experience: React.FC<{}> = () => {
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  return (
    <>
      <div
        className={width > 768 ? "xp-background" : "xp-background-mobile"}
      >
        <div className="xp-container">
          <div className="experience-text-container" data-aos="fade-up" data-aos-delay="300" data-aos-once="true" data-aos-duration="1500">
            <span className="fist">
              20 años liderando la comercialización de TPVs y la transformación
              digital,
            </span>
            <span className="second">
              prestamos servicios a más de 35 industrias.
            </span>
          </div>
        </div>
      </div>

      <div className="exp-footer">
        <Marquee 
          gradient={false}
          speed={50}
          direction={"left"}
          loop={0}
          delay={4}
        >
          <div className="item">
            <img src={Pickit} />
          </div>
          <div className="item">
            <img src={Ablblev} />
          </div>
          <div className="item">
            <img src={Calipso} />
          </div>
          <div className="item">
            <img src={Castia} />
          </div>
          <div className="item">
            <img src={Celeritech} />
          </div>
          <div className="item">
            <img src={ClearChanel} />
          </div>
          <div className="item">
            <img src={huawei} />
          </div>
          <div className="item">
            <img src={Fr8hub} />
          </div>
          <div className="item">
            <img src={Karun} />
          </div>
          <div className="item">
            <img src={Elans} />
          </div>
          <div className="item">
            <img src={Kutshki} />
          </div>
          <div className="item">
            <img src={Lexus} />
          </div>
          <div className="item">
            <img src={Quimitroniac} />
          </div>
          <div className="item">
            <img src={Scalabon} />
          </div>
          <div className="item">
            <img src={SegundaMano} />
          </div>
          <div className="item">
            <img src={Simens} />
          </div>
          <div className="item">
            <img src={Skania} />
          </div>
          <div className="item">
            <img src={Skydropx} />
          </div>
          <div className="item">
            <img src={Teka} />
          </div>
          <div className="item">
            <img src={Toyota} />
          </div>
          <div className="item">
            <img src={Unisef} />
          </div>
          <div className="item">
            <img src={Ventup} />
          </div>
          <div className="item">
            <img src={wildbrands} />
          </div>
          <div className="item">
            <img src={yamaha} />
          </div>
        </Marquee>
      </div>
    </>
  );
};
