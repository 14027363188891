import Job from "../../assets/marketing/Job.svg";
import Service from "../../assets/marketing/Service.svg";
import Business from "../../assets/marketing/Business.svg";
import Hands from "../../assets/marketing/Hands.svg";
import Briefcase from "../../assets/marketing/Briefcase.svg";
import Handset from "../../assets/marketing/Handset.svg";

const dataSales = [
    {
        id: 1,
        image: Job,
        title: 'Creación y coordinación de equipos de ventas',
        text: 'Armamos equipos a medida que se ajustan a tu necesidad.'
    },
    {
        id: 2,
        image: Service,
        title: 'Afiliación de comercios a redes de terminales punto de venta',
        text: 'Facilitamos el procesos de afiliación de manera presencial y telefónica.'
    },
    {
        id: 3,
        image: Business,
        title: 'Introducción de servicios en establecimientos comerciales',
        text: 'Ofrecemos nuevos servicios a negocios ya afiliados.'
    },
    {
        id: 4,
        image: Hands,
        title: 'Negociaciones de alto nivel',
        text: 'Representantes comerciales para negociar con directores o gerentes.'
    },
    {
        id: 5,
        image: Briefcase,
        title: 'Equipos de cambaceo',
        text: 'Nuestro equipo visita negocios para ofrecer tus servicios.'
    },
    {
        id: 6,
        image: Handset,
        title: 'Comercialización vía telemarketing',
        text: 'Callcenter propio para comercializar tus servicios.'
    }
]

const dataService = [
    {
        id: 1,
        image: Job,
        title: 'Encuestas y evaluaciones de servicio presenciales',
        text: `Visitamos los negocios afiliados para relevar cómo están
        utilizando tus servicios.`
    },
    {
        id: 2,
        image: Service,
        title: 'Soporte primer nivel a terminales punto de venta',
        text: `Nuestro equipo de visitas se encarga del primer nivel de
        soporte a los negocios.`
    },
    {
        id: 3,
        image: Business,
        title: 'Señalización de comercios con material POP',
        text: `Señalizamos los comercios con diferentes materiales de tu
        marca.`
    },
    {
        id: 4,
        image: Hands,
        title: 'Generación de prospectos en campo',
        text: `Relevamos candidatos para subir la eficiencia de tu equipo
        de ventas.`
    },
    {
        id: 5,
        image: Briefcase,
        title: 'Entrega de rollos para terminales punto de venta',
        text: 'Abastecemos de insumos a los comercios adheridos.'
    },
]

const dataOther = [
    {
        id: 1,
        image: Job,
        title: 'Auditoría de productos y servicios en punto de venta',
    },
    {
        id: 2,
        image: Service,
        title: 'Mesas de ayuda',
    },
    {
        id: 3,
        image: Business,
        title: 'Soporte y atención al cliente',
    },
    {
        id: 4,
        image: Hands,
        title: 'Campañas telemarketing',
    },
    {
        id: 5,
        image: Briefcase,
        title: 'Cierre de ventas con cargo a tarjetas de crédito y débito',
    },
    {
        id: 6,
        image: Handset,
        title: 'Geolocalización de los puntos de venta.',
    },
    {
        id: 7,
        image: Handset,
        title: 'Instalación y recuperación de terminales punto de venta',
    },
    {
        id: 8,
        image: Handset,
        title: 'Geolocalización de los puntos de venta.',
    }
]


export  {dataSales , dataService , dataOther};