import React, { useState, HTMLAttributes, useRef } from "react";
import "./fullModal.scss";
import Swal from "sweetalert2";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import SendArrow from "../../assets/send-arrow.svg";
import MsgSuccess from "../../assets/message-success.svg";
import MsgRejected from "../../assets/message-rejected.svg";
import emailjs from "emailjs-com";
import LinkedInBlack from "../../assets/contactModal/Linkedin_black.svg";
import CloseModal from "../../assets/contactModal/CloseModal.svg";
import Location from "../../assets/contactModal/Location.svg";
import Phone from "../../assets/contactModal/Phone.svg";
import Procontacto from "../../assets/contactModal/Procontacto_contact.svg";

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}
function Modal(props: ModalProps) {
  const [contact, setContact] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    country_code: "",
    phone: "",
    description: "",
  });
  //State que contenga a todos los errores
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    phone: "",
    description: "",
    country_code: "",
  });
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "phone") {
      if (isNaN(Number(e.target.value))) {
        return;
      }
    }

    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
  };

  const deleteError = () => {
    setErrors({
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      country_code: "",
      phone: "",
      description: "",
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const regexEmail = /[-.\w]+@([\w-]+\.)+[\w-]+/g;
    contact.country_code = selectedValue;

    if (
      contact.first_name === "" ||
      contact.last_name === "" ||
      contact.email === "" ||
      contact.company === "" ||
      contact.phone === "" ||
      contact.description === "" ||
      contact.country_code === ""
    ) {
      setErrors({
        first_name:
          contact.first_name === "" ? "Por favor, indícanos tu nombre." : "",
        last_name:
          contact.last_name === "" ? "Por favor, indícanos tu apellido." : "",
        company:
          contact.company === ""
            ? "Por favor, indícanos el nombre de tu empresa."
            : "",
        email:
          contact.email === ""
            ? "Por favor, compártenos un e-mail para contactarte."
            : !regexEmail.test(contact.email)
            ? "Por favor, ingresa un e-mail válido."
            : "",
        phone:
          contact.phone === ""
            ? "Por favor, compártenos un número de teléfono para contactarte."
            : "",
        description:
          contact.description === ""
            ? "Por favor, cuéntanos en qué podemos ayudarte."
            : "",
        country_code:
          contact.country_code === "" ? "Por favor, selecciona un país." : "",
      });
      return;
    }

    if (!regexEmail.test(contact.email)) {
      setErrorEmail(true);
      return;
    }
    
    if (formRef.current) {
      formRef.current.submit();

      setContact({
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        country_code: "",
        phone: "",
        description: "",
      });
      setSelectedValue("");
    }
  };

  return (
    <div className="fullscreen-modal">
      <div className="modal-content">
        <div className="container-contact">
          {width < 768 && (
            <div className="close-modal">
              <button
                className="close-modal"
                onClick={() => props.setShowModal(false)}
              >
                <img src={CloseModal} alt="close" />
              </button>
            </div>
          )}
          <div className="container-title-modal">
            <div className="contact-title">
              <h1>Cuéntanos,¿cómo podemos ayudarte?</h1>
              <div className="section-contact">
                <img src={Procontacto} alt="procontacto" />
                <div className="contact">
                  <div className="tel">
                    <p>
                      <img src={Phone} alt="tel" /> +52 55 3626 7522
                    </p>
                  </div>
                  <div className="location">
                    <p>
                      <img src={Location} alt="location" /> Av. Revolución 779,
                      Ciudad de México.
                    </p>
                  </div>
                  <div className="linkedin">
                    <a
                      href="https://www.linkedin.com/company/grupoprocontacto/"
                      target="_blank"
                    >
                      <img src={LinkedInBlack} alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form
            className="container-form-modal"
            onSubmit={(e) => handleSubmit(e)}
            onFocus={deleteError}
            ref={formRef}
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
          >
            <input
              type="hidden"
              name="captcha_settings"
              value='{"keyname":"webtoleadkey","fallback":"true","orgId":"00D5e000002HWkU","ts":""}'
            />
            <input type="hidden" name="oid" value="00D5e000002HWkU" />
            <input
              type="hidden"
              name="retURL"
              value="http://www.procontacto.com.mx?form_success=true"
            />

            <div className="form-group">
              <FloatingLabel label="Nombre" className="mb-3">
                <Form.Control
                  type="text"
                  id="first_name"
                  placeholder="Nombre"
                  value={contact.first_name}
                  name="first_name"
                  onChange={handleChange}
                  {...(errors.first_name && { className: "error" })}
                />

                {errors.first_name && (
                  <p className="error-text">{errors.first_name}</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Apellido" className="mb-3">
                <Form.Control
                  type="text"
                  id="last_name"
                  placeholder="Apellido"
                  value={contact.last_name}
                  name="last_name"
                  onChange={handleChange}
                  {...(errors.last_name && { className: "error" })}
                />

                {errors.last_name && (
                  <p className="error-text">{errors.last_name}</p>
                )}
              </FloatingLabel>

              <FloatingLabel
                label="E-mail"
                className="mb-3"
                {...(errorEmail && { className: "error-text-email" })}
              >
                <Form.Control
                  type="text"
                  name="email"
                  id="email"
                  value={contact.email}
                  placeholder="Email"
                  onChange={handleChange}
                  {...(errors.email && { className: "error" })}
                  {...(errorEmail && { className: "errorEmail" })}
                  onFocus={() => setErrorEmail(false)}
                />

                {errorEmail && (
                  <div className="error-text">
                    <p>Por favor, ingresa un e-mail válido.</p>
                  </div>
                )}
                {errors.email && <p className="error-text">{errors.email}</p>}
              </FloatingLabel>

              <FloatingLabel label="Empresa" className="mb-3">
                <Form.Control
                  type="text"
                  name="company"
                  id="company"
                  value={contact.company}
                  placeholder="Empresa"
                  onChange={handleChange}
                  {...(errors.company && { className: "error" })}
                />
                {errors.company && (
                  <p className="error-text">{errors.company}</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Teléfono" className="mb-3">
                <Form.Control
                  type="text"
                  name="phone"
                  id="phone"
                  value={contact.phone}
                  placeholder="Teléfono"
                  onChange={handleChange}
                  {...(errors.phone && { className: "error" })}
                />
                {errors.phone && <p className="error-text">{errors.phone}</p>}
              </FloatingLabel>

              <FloatingLabel label="País">
                <Form.Select
                  value={selectedValue}
                  onChange={handleChangeSelect}
                  id="country_code"
                  className={
                    selectedValue === "" ? "select-default" : "select-selected"
                  }
                  {...(errors.country_code && {
                    className: "error select-default",
                  })}
                >
                  <option value="" disabled hidden>
                    Seleccione un país
                  </option>
                  <option value="AR">Argentina</option>
                  <option value="BO">Bolivia</option>
                  <option value="CL">Chile</option>
                  <option value="CO">Colombia</option>
                  <option value="EC">Ecuador</option>
                  <option value="MX">Mexico</option>
                  <option value="PA">Panama</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="ES">Spain</option>
                  <option value="US">United States</option>
                  <option value="UY">Uruguay</option>
                </Form.Select>

                {errors.country_code && (
                  <p className="error-text">{errors.country_code}</p>
                )}
              </FloatingLabel>

              <FloatingLabel label="Mensaje" className="mb-3">
                <Form.Control
                  as="textarea"
                  name="description"
                  value={contact.description}
                  placeholder="Leave a comment here"
                  style={{ height: "150px" }}
                  onChange={handleChange}
                  {...(errors.description && { className: "error" })}
                />
                {errors.description && (
                  <p className="error-text">{errors.description}</p>
                )}
              </FloatingLabel>

              <div
                className="g-recaptcha"
                data-sitekey="6LfXIEEkAAAAAHiuMYJwCzVCfKvqD1ZqdZJ0Rguj"
              ></div>

              <button className="send-button-modal" type="submit">
                ENVIAR MENSAJE <img src={SendArrow} />
              </button>
            </div>
          </form>
          {width > 768 && (
            <div className="close-modal">
              <button
                className="close-modal"
                onClick={() => props.setShowModal(false)}
              >
                <img src={CloseModal} alt="close" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
