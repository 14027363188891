import "./SoftwareFactory.scss";
import AboutImage from "../../assets/AboutImage.svg";
import softwareFactory from "../../assets/software/softwareFactory.svg";
import Gif1 from "../../assets/software/GIF1.gif";
import Gif2 from "../../assets/software/GIF2.gif";
import Team from "../../assets/software/imagenTeam.svg";
import { data, services } from "./software";

export const SoftwareFactory: React.FC<{}> = () => {
  return (
    <div className="container-software">
      <div className="section-title">
        <h3 className="subtitle-software">Software Factory</h3>
        <h1 className="title-software">
          Transforma ideas en realidad con nuestro equipo experto
          <br></br>en desarrollo de software.
        </h1>
        <div className="image-software">
          <img src={softwareFactory} />
        </div>
      </div>
      <div className="section-information">
        {data.map((item) => (
          <div className="card-information" key={item.id}>
            <img src={item.image} />
            <p className="description">{item.description}</p>
          </div>
        ))}
      </div>

      <div className="section-team">
        <h1 className="title-team">Nuestro equipo</h1>
        <div className="team">
          <img className="gif" src={Gif1} />
          <img className="image-team" src={Team} />
          <img className="gif" src={Gif2} />
        </div>
      </div>

      <div className="section-services">
        <h1 className="title-services">Servicios</h1>
        <div className="section-card-services">
          {services.map((item) => (
            <div className="card-information" key={item.id}>
              <img className="image-information" src={item.image} />
              <div className="section-services-card">
                <h3 className="title">{item.title}</h3>
                <p className="description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
