import "./Salesforce.scss";
import React, { useState } from "react";
import DownArrow from "../../assets/down-arrow.svg";
import DownArrowHover from "../../assets/down-arrow-hover.svg";
import Arrow from "../../assets/arrow.svg";
import Sales from "../../assets/salesforce/Sales.svg";
import Service from "../../assets/salesforce/Service.svg";
import Commerce from "../../assets/salesforce/Commerce.svg";
import Marketing from "../../assets/salesforce/Marketing.svg";
import Mulesoft from "../../assets/salesforce/Mulesoft.svg";
import "pure-react-carousel/dist/react-carousel.es.css";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import SalesforceCustomer from "../../assets/salesforce/image-customer.svg";
import SalesforceCustomerMobile from "../../assets/salesforce/Industrias.svg";
import SalesforceVideo from "../../assets/salesforce/video.mp4";
import SalesforceGif from "../../assets/salesforce/SalesforceGIF.jpg";
import services from "./services";
import business from "./business";
import {
  argentina,
  chile,
  colombia,
  costaRica,
  ecuador,
  mexico,
  peru,
} from "./clients";
import { Contact } from "../Contact/Contact";

export const Salesforce: React.FC<{}> = () => {
  const [active, setActive] = useState({
    argentina: true,
    chile: false,
    colombia: false,
    colombia1: false,
    costaRica: false,
    ecuador: false,
    mexico: false,
    peru: false,
  });

  const scrollToSectionCarousel = () => {
    const section2 = document.getElementById("sectionBusiness");
    section2?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  const detectedClick = (e: any) => {
    const classList = e.target.classList;

    switch (classList[1]) {
      case "chile":
        setActive({
          argentina: false,
          chile: true,
          colombia: false,
          colombia1: false,
          costaRica: false,
          ecuador: false,
          mexico: false,
          peru: false,
        });
        break;
      case "colombia":
        setActive({
          argentina: false,
          chile: false,
          colombia: true,
          colombia1: false,
          costaRica: false,
          ecuador: false,
          mexico: false,
          peru: false,
        });
        break;
      case "colombia1":
        setActive({
          argentina: false,
          chile: false,
          colombia: false,
          colombia1: true,
          costaRica: false,
          ecuador: false,
          mexico: false,
          peru: false,
        });
        break;
      case "costaRica":
        setActive({
          argentina: false,
          chile: false,
          colombia: false,
          colombia1: false,
          costaRica: true,
          ecuador: false,
          mexico: false,
          peru: false,
        });
        break;
      case "ecuador":
        setActive({
          argentina: false,
          chile: false,
          colombia: false,
          colombia1: false,
          costaRica: false,
          ecuador: true,
          mexico: false,
          peru: false,
        });
        break;
      case "mexico":
        setActive({
          argentina: false,
          chile: false,
          colombia: false,
          colombia1: false,
          costaRica: false,
          ecuador: false,
          mexico: true,
          peru: false,
        });
        break;
      case "peru":
        setActive({
          argentina: false,
          chile: false,
          colombia: false,
          colombia1: false,
          costaRica: false,
          ecuador: false,
          mexico: false,
          peru: true,
        });
        break;
      default:
        setActive({
          argentina: true,
          chile: false,
          colombia: false,
          colombia1: false,
          costaRica: false,
          ecuador: false,
          mexico: false,
          peru: false,
        });
        break;
    }
  };

  return (
    <div className="container-salesforce">
      <div className="section-title">
        <h3 className="subtitle-salesforce">Salesforce</h3>
        <h1 className="title-salesforce">
          Tecnología para tu empresa implementada por expertos.
        </h1>
        <button className="button-contact">
          Contactate <img src={Arrow} />
        </button>
        <div className="image-salesforce">
          <img src={SalesforceGif}/>
        </div>
        <button className="down-button" onClick={scrollToSectionCarousel}>
          <img className="down-arrow normal" src={DownArrow} />
          <img className="down-arrow hover" src={DownArrowHover} />
        </button>
      </div>

      <div className="business-desktop">
        <div className="container-business">
          <video
            src={SalesforceVideo}
            autoPlay
            loop
            muted
            className="img-business"
          ></video>
          <div className="section-title-business">
            <h1 className="title-business">Tu negocio es nuestra prioridad</h1>
          </div>
          <div></div>
          <div className="section-cards">
            {business.map((business) => (
              <div
                className="container-cards"
                id="sectionBusiness"
                key={business.id}
              >
                <div className="container-image">
                  <img src={business.image} />
                </div>
                <div className="container-text">
                  <p className="text-card">{business.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="business-mobile">
        <div className="container-business">
          <video
            src={SalesforceVideo}
            autoPlay
            loop
            muted
            className="img-business"
          ></video>
          <h1 className="title-business">
            Tu negocio es<br></br>nuestra prioridad
          </h1>
        </div>
        <div className="section-cards">
          <CarouselProvider
            naturalSlideWidth={10}
            naturalSlideHeight={10}
            totalSlides={4}
            visibleSlides={1}
          >
            <Slider className="slider-business">
              {business.map((business, index) => (
                <Slide index={index} key={business.id}>
                  <div className="container-cards" id="sectionBusiness">
                    <div className="container-image">
                      <img src={business.image} />
                    </div>
                    <div className="container-text">
                      <p className="text-card">{business.description}</p>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </div>
      </div>

      <div className="container-service">
        <h1 className="text-service">Nuestros servicios</h1>
        <div className="cards-services">
          {services.map((service) => (
            <div className="card-service" key={service.id}>
              <img src={service.image} />
              <h3 className="card-title">{service.name}</h3>
              <p className="">{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="container-customer">
        <h1 className="title-customer">Customer 360</h1>
        <p className="subtitle-customer">
          Las empresas que trabajan con ProContacto tienen la vista 360 de sus
          clientes porque somos expertos en la implementación de las siguientes
          nubes
        </p>

        <div className="customer-desktop">
          <div className="container-cards-icon">
            <div className="section-icons">
              <div className="first-section">
                <div className="cards-icon">
                  <img src={Sales} />
                  <p className="text-sales">Sales Cloud</p>
                </div>
                <div className="cards-icon">
                  <img src={Service} />
                  <p className="text-service">Service Cloud</p>
                </div>
              </div>
              <div className="second-section">
                <div className="cards-icon">
                  <img src={Commerce} />
                  <p className="text-commerce">Commerce Cloud</p>
                </div>
                <div className="cards-icon">
                  <img src={Marketing} />
                  <p className="text-marketing">Marketing Cloud</p>
                </div>
                <div className="cards-icon">
                  <img src={Mulesoft} />
                  <p className="text-mulesoft">Mulesoft</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="customer-mobile">
          <div className="container-cards-icon">
            <div className="section-icons">
              <div className="first-section">
                <div className="cards-icon">
                  <img src={Sales} />
                  <p className="text-sales">Sales Cloud</p>
                </div>
                <div className="cards-icon">
                  <img src={Service} />
                  <p className="text-service">Service Cloud</p>
                </div>
              </div>
              <div className="second-section">
                <div className="cards-icon">
                  <img src={Commerce} />
                  <p className="text-commerce">Commerce Cloud</p>
                </div>
                <div className="cards-icon">
                  <img src={Marketing} />
                  <p className="text-marketing">Marketing Cloud</p>
                </div>
              </div>
              <div className="third-section">
                <div className="cards-icon">
                  <img src={Mulesoft} />
                  <p className="text-mulesoft">Mulesoft</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h3 className="text-customer">
          Implementamos las nubes de Salesforce específicas para estas
          industrias
        </h3>

        <div className="container-image-customer">
          <img className="img-customer-desktop" src={SalesforceCustomer} />
          <img className="img-customer-mobile" src={SalesforceCustomerMobile} />
        </div>
      </div>

      <div className="container-clients">
        <h1 className="title-clients">Nuestros clientes en LATAM</h1>
        <div className="section-clients-buttons">
          <button
            type="button"
            className={
              active.argentina
                ? "button-clients argentina selected"
                : "button-clients argentina"
            }
            onClick={detectedClick}
          >
            Argentina
          </button>
          <button
            type="button"
            className={
              active.chile
                ? "button-clients chile selected"
                : "button-clients chile"
            }
            onClick={detectedClick}
          >
            Chile
          </button>
          <button
            type="button"
            className={
              active.colombia
                ? "button-clients colombia selected"
                : "button-clients colombia"
            }
            onClick={detectedClick}
          >
            Colombia
          </button>
          <button
            type="button"
            className={
              active.costaRica
                ? "button-clients costaRica selected"
                : "button-clients costaRica"
            }
            onClick={detectedClick}
          >
            Costa Rica
          </button>
          <button
            type="button"
            className={
              active.ecuador
                ? "button-clients ecuador selected"
                : "button-clients ecuador"
            }
            onClick={detectedClick}
          >
            Ecuador
          </button>
          <button
            type="button"
            className={
              active.mexico
                ? "button-clients mexico selected"
                : "button-clients mexico"
            }
            onClick={detectedClick}
          >
            México
          </button>
          <button
            type="button"
            className={
              active.peru
                ? "button-clients peru selected"
                : "button-clients peru"
            }
            onClick={detectedClick}
          >
            Perú
          </button>
        </div>

        <div
          className={
            active.argentina
              ? "clients-card-image clients-argentina"
              : "clients-card-image clients-argentina hide"
          }
        >
          {argentina.map((images) => (
            <div className="container-clients-image" key={images.id}>
              <img src={images.image} />
            </div>
          ))}
        </div>

        <div
          className={
            active.chile
              ? "clients-card-image clients-chile"
              : "clients-card-image clients-chile hide"
          }
        >
          {chile.map((images) => (
            <div className="container-clients-image" key={images.id}>
              <img src={images.image} />
            </div>
          ))}
        </div>

        <div
          className={
            active.colombia
              ? "clients-card-image clients-colombia"
              : "clients-card-image clients-colombia hide"
          }
        >
          {colombia.map((images) => (
            <div className="container-clients-image" key={images.id}>
              <img src={images.image} />
            </div>
          ))}
        </div>

        <div
          className={
            active.costaRica
              ? "clients-card-image clients-costaRica"
              : "clients-card-image clients-costaRica hide"
          }
        >
          {costaRica.map((images) => (
            <div className="container-clients-image" key={images.id}>
              <img src={images.image} />
            </div>
          ))}
        </div>

        <div
          className={
            active.ecuador
              ? "clients-card-image clients-ecuador"
              : "clients-card-image clients-ecuador hide"
          }
        >
          {ecuador.map((images) => (
            <div className="container-clients-image" key={images.id}>
              <img src={images.image} />
            </div>
          ))}
        </div>

        <div
          className={
            active.mexico
              ? "clients-card-image clients-mexico"
              : "clients-card-image clients-mexico hide"
          }
        >
          {mexico.map((images) => (
            <div className="container-clients-image" key={images.id}>
              <img src={images.image} />
            </div>
          ))}
        </div>

        <div
          className={
            active.peru
              ? "clients-card-image clients-peru"
              : "clients-card-image clients-peru hide"
          }
        >
          {peru.map((images) => (
            <div className="container-clients-image" key={images.id}>
              <img src={images.image} />
            </div>
          ))}
        </div>
      </div>

      <Contact />
    </div>
  );
};
