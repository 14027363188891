import React, { useState } from 'react';
import { Header } from '../components/Header/Header';
import Start from "../assets/background/start.svg"
import Circle from "../assets/background/circle.svg"
import { Info } from '../components/Info/Info';
import { Experience } from '../components/Experience/Experience';
import { Contact } from '../components/Contact/Contact';
import { Footer } from '../components/Footer/Footer';
import { Site } from '../components/Site/Site';
import '../App.scss';

export const Home: React.FC<{}> = () => {
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <div className='app-container'>
      {width > 768 ? (
        <div className='background'>
          <img src={Start} className="image-start" />
          <img src={Circle} className="image-circle" />
        </div>)
        : (
          null
        )
      }
      <Header />
      <Site />
      <Info/>
      <Experience/>
      <Contact/>
      <Footer />
    </div>
  );
};