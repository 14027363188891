import "./InformationServices.scss";
import ServicesImage from "../../assets/ServicesImage.svg"
import DownArrow from "../../assets/down-arrow.svg";
import DownArrowHover from "../../assets/down-arrow-hover.svg";


import { CarouselDesktop } from "../Carousel/CarouselDesktop";
import { CarouselMobile } from "../Carousel/CarouselMobile";

export const InformationServices: React.FC<{}> = () => {
    const scrollToSectionCarousel = () => {
        const section2 = document.getElementById("sectionCarousel");
        section2?.scrollIntoView({ behavior: "smooth" });
    };
    
    return (
        <div className="container-information">
            <div className="section-title">
                <h3 className="subtitle-services">Servicios Comerciales</h3>
                <h1 className="title-services">Crea una fuerza de ventas a tu medida según las necesidades de cada momento.</h1>
                <div className="image-services">
                    <img src={ServicesImage} />
                </div>
                <button className="down-button" onClick={scrollToSectionCarousel}>
                    <img className="down-arrow normal" src={DownArrow} />
                    <img className="down-arrow hover" src={DownArrowHover} />
                </button>
            </div>

            <div className="container-desktop">
                <CarouselDesktop/>
            </div>

            <div className="container-mobile">
                <CarouselMobile/>
            </div>
        </div>
    );
};