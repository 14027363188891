import { Contact } from "../components/Contact/Contact";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { InformationServices } from "../components/InfoServices/InformationServices";

export const CommercialServices: React.FC<{}> = () => {
    return (
        <>
            <Header />
            <InformationServices />
            <Contact/>
            <Footer />
        </>
    );
};