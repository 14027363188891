import { Header } from "../components/Header/Header";
import { Footer } from '../components/Footer/Footer';
import { Work } from "../components/Work/Work";

export const Job: React.FC<{}> = () => {
    return (
        <>
            <Header />
            <Work />
            <Footer />
        </>
    );
};