import Talento from "../../assets/about/Talento.svg";
import Equipo from "../../assets/about/Equipo.svg";
import ServicioEquipo from "../../assets/software/servicioEquipo.svg";
import ServicioProyecto from "../../assets/software/servicioProyecto.svg";


const data = [{
        id: 1,
        image: Talento,
        description: `ProContacto cuenta con +15 años de experiencia en la construcción de software, empleando frameworks, metodologías 
        y prácticas como Scrum, DevOps y Kanban. Nuestra visión ordenada incluye fechas 
        y objetivos claros, lo que facilita 
        la planificación, el seguimiento y la comunicación entre equipos para lograr 
        el éxito en los proyectos.`
    },
    {
        id: 2,
        image: Equipo,
        description: `Aseguramos la calidad de nuestro servicio a 
        través de profesionales altamente
        capacitados. Para eso, buscamos contratar a 
        los mejores ingenieros de software en 
        Latinoamérica, para lo cual llevamos a cabo 
        un riguroso proceso de selección que incluye 
        entrevistas técnicas y evaluaciones de 
        habilidades blandas.`
    }
]


const services = [{
        id: 1,
        image: ServicioEquipo,
        title: "Equipo Dedicado",
        description: `PorContacto provee todo los roles que necesitan para formar el equipo que requieres. Generalmente están compuestos por PM, SW engineers, Developers, QA engineers y otros roles definidos específicamente en cada proyecto. La gestión se encuentra conducida de forma conjunta por un Scrum Master/Project Manager y el Product Owner del cliente.
    Un equipo dedicado trabaja de forma autónoma pero comunica y reporta estados con la frecuencia que se solicite. Si se escoge una metodología Agile, el equipo recibirá feedback de forma constante para mejorar el producto.`
    },
    {
        id: 2,
        image: ServicioProyecto,
        title: "Contratación por proyectos",
        description: `IT Consulting & Strategy: Como parte de nuestro servicio end-to-end 
    en el ciclo de vida del desarrollo de software, Procontacto ofrece el conocimiento para ayudar en la construcción de productos digital. Podemos colaborar desde el ¿Qué hacer? hasta ¿Cómo hacerlo?, desenvolviéndose en distintas aristas tales como el análisis inicial, construcción de roadmap, desarrollo, diseño, seguimiento, etc.`
    }
]

export  {data , services };
