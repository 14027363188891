import { Header } from "../components/Header/Header";
import { Privacy } from "../components/Privacy/Privacy";
import { Footer } from '../components/Footer/Footer';

export const PrivacyPolicy: React.FC<{}> = () => {
    return (
        <>
            <Header />
            <Privacy />
            <Footer />
        </>
    );
};