import ABInBev from "../../assets/salesforce/clients/arg/ABInBev.svg";
import Astatec from "../../assets/salesforce/clients/arg/Astatec.svg";
import Bedson from "../../assets/salesforce/clients/arg/Bedson.svg";
import Bercomat from "../../assets/salesforce/clients/arg/Bercomat.svg";
import Credikot from "../../assets/salesforce/clients/arg/Credikot.svg";
import Flugel from "../../assets/salesforce/clients/arg/Flugel.svg";
import Ocasa from "../../assets/salesforce/clients/arg/Ocasa.svg";
import Pickit from "../../assets/salesforce/clients/arg/Pickit.svg";
import Siemens from "../../assets/salesforce/clients/arg/Siemens.svg";
import Sigma from "../../assets/salesforce/clients/arg/Sigma.svg";
import Unilever from "../../assets/salesforce/clients/arg/Unilever.svg";
import Willian from "../../assets/salesforce/clients/arg/Willian.svg";

import Bioelemts from "../../assets/salesforce/clients/chile/Bioelemts.svg";
import Chilelift from "../../assets/salesforce/clients/chile/Chilelift.svg";
import CrealChannel from "../../assets/salesforce/clients/chile/ClearChannel.svg";
import Everest from "../../assets/salesforce/clients/chile/Everest.svg";
import Garibaldi from "../../assets/salesforce/clients/chile/Garibaldi.svg";
import Karun from "../../assets/salesforce/clients/chile/Karun.svg";
import Solmax from "../../assets/salesforce/clients/chile/Solmax.svg";
import Toctoc from "../../assets/salesforce/clients/chile/Toctoc.svg";
import TuCopiloto from "../../assets/salesforce/clients/chile/TuCopiloto.svg";
import Vega from "../../assets/salesforce/clients/chile/Vega.svg";
import Vidadvision from "../../assets/salesforce/clients/chile/Vidavision.svg";
import WildBrands from "../../assets/salesforce/clients/chile/WildBrands.svg";


import Acasa from "../../assets/salesforce/clients/colombia/Acasa.svg";
import Aliar from "../../assets/salesforce/clients/colombia/Aliar.svg";
import Calypso from "../../assets/salesforce/clients/colombia/Calypso.svg";
import Delasabana from "../../assets/salesforce/clients/colombia/Delasabana.svg";
import Elenas from "../../assets/salesforce/clients/colombia/Elenas.svg";
import Finaktiva from "../../assets/salesforce/clients/colombia/Finaktiva.svg";
import H from "../../assets/salesforce/clients/colombia/H.svg";
import Infinita from "../../assets/salesforce/clients/colombia/Infinita.svg";
import Kushki from "../../assets/salesforce/clients/colombia/Kushki.svg";
import Opcionyo from "../../assets/salesforce/clients/colombia/Opcionyo.svg";
import Quimitronica from "../../assets/salesforce/clients/colombia/Quimitronica.svg";
import Wowcan from "../../assets/salesforce/clients/colombia/Wowcan.svg";

import Xtreme from "../../assets/salesforce/clients/costaRica/Xtreme.svg";

import Teka from "../../assets/salesforce/clients/ecuador/Teka.svg";

import Ahern from "../../assets/salesforce/clients/mexico/Ahern.svg";
import Arrenda from "../../assets/salesforce/clients/mexico/Arrenda.svg";
import Avigrupo from "../../assets/salesforce/clients/mexico/Avigrupo.svg";
import Canels from "../../assets/salesforce/clients/mexico/Canels.svg";
import Capem from "../../assets/salesforce/clients/mexico/Capem.svg";
import Certsuperior from "../../assets/salesforce/clients/mexico/Certsuperior.svg";
import Coral from "../../assets/salesforce/clients/mexico/Coral.svg";
import Eslabon from "../../assets/salesforce/clients/mexico/Eslabon.svg";
import Fratello from "../../assets/salesforce/clients/mexico/Fratello.svg";
import Ientc from "../../assets/salesforce/clients/mexico/Ientc.svg";
import Innovak from "../../assets/salesforce/clients/mexico/Innovak.svg";
import Mabe from "../../assets/salesforce/clients/mexico/Mabe.svg";
import Scania from "../../assets/salesforce/clients/mexico/Scania.svg";
import Segundamano from "../../assets/salesforce/clients/mexico/Segundamano.svg";
import Skydropx from "../../assets/salesforce/clients/mexico/Skydropx.svg";
import Smn from "../../assets/salesforce/clients/mexico/Smn.svg";
import Toyota from "../../assets/salesforce/clients/mexico/Toyota.svg";
import Ventup from "../../assets/salesforce/clients/mexico/Ventup.svg";

import CeleriTech from "../../assets/salesforce/clients/peru/CeleriTech.svg";
import Ckm from "../../assets/salesforce/clients/peru/Ckm.svg";
import Emtec from "../../assets/salesforce/clients/peru/Emtec.svg";
import Fitness from "../../assets/salesforce/clients/peru/Fitness.svg";
import Mechanical from "../../assets/salesforce/clients/peru/Mechanical.svg";
import Quetal from "../../assets/salesforce/clients/peru/Quetal.svg";
import Texcope from "../../assets/salesforce/clients/peru/Texcope.svg";
import Tomonorte from "../../assets/salesforce/clients/peru/Tomonorte.svg";
import Yamaha from "../../assets/salesforce/clients/peru/Yamaha.svg";


const argentina = [
  {
    id: 1,
    image: ABInBev,
  },
  {
    id: 2,
    image: Siemens,
  },
  {
    id: 3,
    image: Pickit,
  },
  {
    id: 4,
    image: Sigma,
  },
  {
    id: 5,
    image: Willian,
  },
  {
    id: 6,
    image: Bercomat,
  },
  {
    id: 7,
    image: Astatec,
  },
  {
    id: 8,
    image: Unilever,
  },
  {
    id: 9,
    image: Bedson,
  },
  {
    id: 10,
    image: Ocasa,
  },
  {
    id: 11,
    image: Flugel,
  },
  {
    id: 12,
    image: Credikot,
  },
];

const chile = [
  {
    id: 1,
    image: CrealChannel,
  },
  {
    id: 2,
    image: WildBrands,
  },
  {
    id: 3,
    image: Karun,
  },
  {
    id: 4,
    image: TuCopiloto,
  },
  {
    id: 5,
    image: Everest,
  },
  {
    id: 6,
    image: Vega,
  },
  {
    id: 7,
    image: Chilelift,
  },
  {
    id: 8,
    image: Vidadvision,
  },
  {
    id: 9,
    image: Toctoc,
  },
  {
    id: 10,
    image: Solmax,
  },
  {
    id: 11,
    image: Garibaldi,
  },
  {
    id: 12,
    image: Bioelemts,
  },
];

const colombia = [
  {
    id: 1,
    image: Elenas,
  },
  {
    id: 2,
    image: Kushki,
  },
  {
    id: 3,
    image: Calypso,
  },
  {
    id: 4,
    image: Quimitronica,
  },
  {
    id: 5,
    image: Infinita,
  },
  {
    id: 6,
    image: Aliar,
  },
  {
    id: 7,
    image: Finaktiva,
  },
  {
    id: 8,
    image: Opcionyo,
  },
  {
    id: 9,
    image: Acasa,
  },
  {
    id: 10,
    image: H,
  },
  {
    id: 11,
    image: Wowcan,
  },
  {
    id: 12,
    image: Delasabana,
  },
];

const costaRica = [
  {
    id: 1,
    image: Xtreme,
  },
];

const ecuador = [
  {
    id: 1,
    image: Teka,
  },
];

const mexico = [
  {
    id: 1,
    image: Scania,
  },
  {
    id: 2,
    image: Skydropx,
  },
  {
    id: 3,
    image: Segundamano,
  },
  {
    id: 4,
    image: Ahern,
  },
  {
    id: 5,
    image: Ventup,
  },
  {
    id: 6,
    image: Eslabon,
  },
  {
    id: 7,
    image: Avigrupo,
  },
  {
    id: 8,
    image: Coral,
  },
  {
    id: 9,
    image: Ientc,
  },
  {
    id: 10,
    image: Toyota,
  },
  {
    id: 11,
    image: Innovak,
  },
  {
    id: 12,
    image: Canels,
  },
  {
    id: 13,
    image: Certsuperior,
  },
  {
    id: 14,
    image: Capem,
  },
  {
    id: 15,
    image: Smn,
  },
  {
    id: 16,
    image: Fratello,
  },
  {
    id: 17,
    image: Mabe,
  },
  {
    id: 18,
    image: Arrenda,
  },
];

const peru = [
  {
    id: 1,
    image: Yamaha,
  },
  {
    id: 2,
    image: CeleriTech,
  },
  {
    id: 3,
    image: Texcope,
  },
  {
    id: 4,
    image: Ckm,
  },
  {
    id: 5,
    image: Fitness,
  },
  {
    id: 6,
    image: Quetal,
  },
  {
    id: 7,
    image: Tomonorte,
  },
  {
    id: 8,
    image: Emtec,
  },
  {
    id: 9,
    image: Mechanical,
  },
];

export {
  argentina,
  chile,
  colombia,
  costaRica,
  ecuador,
  mexico,
  peru,
};
