const listsJobs = [
    {
        "id": "1",
        "position": "Desarrollador Backend SSr.",
        "location": "Ciudad de mexico",
        "date": "03 jun 2022",
        "description": `Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación. Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?`,
        "responsabilities": [
            "Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación.",
            "Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?",
        ],
        "challenges": [
            "Serás owner de varios módulos de la aplicación de cara al usuario final, con   tecnologías Android, iOS, Node, Java.",
            "Esperamos que propongas diseños técnicos escalables, simples, mantenibles y resilientes.",
            "Que seas un embajador de la calidad de código y de las buenas prácticas, diriamos que es un must!",
            "Estarás junto al equipo de UX y al equipo de Producto, siendo responsable de la solución técnica de punta a punta y la integración con otros módulos.",
            "Que te sientas cómodo mostrando el impacto que genera tu equipo frente a los stakeholders de la Compañía.",
            "Y podrás armar tu propio equipo en conjunto con el equipo de Talent Acquisition."
        ],
        "requirements": [
            "Sólida experiencia Desarrollando con Java.",
            "Idealmente que hayas trabajado con microservicios.",
            "Poseer experiencia liderando equipos de alto rendimiento.",
            "Proponer Mejoras simples en IT con impacto en el negocio.",
            "Que disfrutes trabajando en equipo es clave, estamos construyendo una cultura 100% colaborativa.",
            "Disfrutar de los cambios y oportunidades, no te olvides que estamos en pleno crecimiento.",
            "Ser una persona dispuesta a proponer y mejoras procesos!, queremos aprender de vos!"
        ],
        "city": "México",
    },
    {
        "id": "2",
        "position": "Content Designer Sr.",
        "location": "Ciudad de mexico",
        "date": "03 jun 2022",
        "description": `Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación. Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?`,
        "responsabilities": [
            "Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación.",
            "Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?",
        ],
        "challenges": [
            "Serás owner de varios módulos de la aplicación de cara al usuario final, con   tecnologías Android, iOS, Node, Java.",
            "Esperamos que propongas diseños técnicos escalables, simples, mantenibles y resilientes.",
            "Que seas un embajador de la calidad de código y de las buenas prácticas, diriamos que es un must!",
            "Estarás junto al equipo de UX y al equipo de Producto, siendo responsable de la solución técnica de punta a punta y la integración con otros módulos.",
            "Que te sientas cómodo mostrando el impacto que genera tu equipo frente a los stakeholders de la Compañía.",
            "Y podrás armar tu propio equipo en conjunto con el equipo de Talent Acquisition."
        ],
        "requirements": [
            "Sólida experiencia Desarrollando con Java.",
            "Idealmente que hayas trabajado con microservicios.",
            "Poseer experiencia liderando equipos de alto rendimiento.",
            "Proponer Mejoras simples en IT con impacto en el negocio.",
            "Que disfrutes trabajando en equipo es clave, estamos construyendo una cultura 100% colaborativa.",
            "Disfrutar de los cambios y oportunidades, no te olvides que estamos en pleno crecimiento.",
            "Ser una persona dispuesta a proponer y mejoras procesos!, queremos aprender de vos!"
        ],
        "city": "México",
    },
    {
        "id": "3",
        "position": "Team Leader Frontend.",
        "location": "Remoto",
        "date": "12 ABR 2022",
        "description": `Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación. Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?`,
        "responsabilities": [
            "Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación.",
            "Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?",
        ],
        "challenges": [
            "Serás owner de varios módulos de la aplicación de cara al usuario final, con   tecnologías Android, iOS, Node, Java.",
            "Esperamos que propongas diseños técnicos escalables, simples, mantenibles y resilientes.",
            "Que seas un embajador de la calidad de código y de las buenas prácticas, diriamos que es un must!",
            "Estarás junto al equipo de UX y al equipo de Producto, siendo responsable de la solución técnica de punta a punta y la integración con otros módulos.",
            "Que te sientas cómodo mostrando el impacto que genera tu equipo frente a los stakeholders de la Compañía.",
            "Y podrás armar tu propio equipo en conjunto con el equipo de Talent Acquisition."
        ],
        "requirements": [
            "Sólida experiencia Desarrollando con Java.",
            "Idealmente que hayas trabajado con microservicios.",
            "Poseer experiencia liderando equipos de alto rendimiento.",
            "Proponer Mejoras simples en IT con impacto en el negocio.",
            "Que disfrutes trabajando en equipo es clave, estamos construyendo una cultura 100% colaborativa.",
            "Disfrutar de los cambios y oportunidades, no te olvides que estamos en pleno crecimiento.",
            "Ser una persona dispuesta a proponer y mejoras procesos!, queremos aprender de vos!"
        ],
        "city": "Argentina",
    },
    {
        "id": "4",
        "position": "Team Leader Backend.",
        "location": "Ciudad de mexico",
        "date": "26 FEB 2022",
        "description": `Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación. Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?`,
        "responsabilities": [
            "Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación.",
            "Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?",
        ],
        "challenges": [
            "Serás owner de varios módulos de la aplicación de cara al usuario final, con   tecnologías Android, iOS, Node, Java.",
            "Esperamos que propongas diseños técnicos escalables, simples, mantenibles y resilientes.",
            "Que seas un embajador de la calidad de código y de las buenas prácticas, diriamos que es un must!",
            "Estarás junto al equipo de UX y al equipo de Producto, siendo responsable de la solución técnica de punta a punta y la integración con otros módulos.",
            "Que te sientas cómodo mostrando el impacto que genera tu equipo frente a los stakeholders de la Compañía.",
            "Y podrás armar tu propio equipo en conjunto con el equipo de Talent Acquisition."
        ],
        "requirements": [
            "Sólida experiencia Desarrollando con Java.",
            "Idealmente que hayas trabajado con microservicios.",
            "Poseer experiencia liderando equipos de alto rendimiento.",
            "Proponer Mejoras simples en IT con impacto en el negocio.",
            "Que disfrutes trabajando en equipo es clave, estamos construyendo una cultura 100% colaborativa.",
            "Disfrutar de los cambios y oportunidades, no te olvides que estamos en pleno crecimiento.",
            "Ser una persona dispuesta a proponer y mejoras procesos!, queremos aprender de vos!"
        ],
        "city": "México",
    },
    {
        "id": "5",
        "position": "Team Leader Backend.",
        "location": "Ciudad de mexico",
        "date": "26 FEB 2022",
        "description": `Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación. Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?`,
        "responsabilities": [
            "Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación.",
            "Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?",
        ],
        "challenges": [
            "Serás owner de varios módulos de la aplicación de cara al usuario final, con   tecnologías Android, iOS, Node, Java.",
            "Esperamos que propongas diseños técnicos escalables, simples, mantenibles y resilientes.",
            "Que seas un embajador de la calidad de código y de las buenas prácticas, diriamos que es un must!",
            "Estarás junto al equipo de UX y al equipo de Producto, siendo responsable de la solución técnica de punta a punta y la integración con otros módulos.",
            "Que te sientas cómodo mostrando el impacto que genera tu equipo frente a los stakeholders de la Compañía.",
            "Y podrás armar tu propio equipo en conjunto con el equipo de Talent Acquisition."
        ],
        "requirements": [
            "Sólida experiencia Desarrollando con Java.",
            "Idealmente que hayas trabajado con microservicios.",
            "Poseer experiencia liderando equipos de alto rendimiento.",
            "Proponer Mejoras simples en IT con impacto en el negocio.",
            "Que disfrutes trabajando en equipo es clave, estamos construyendo una cultura 100% colaborativa.",
            "Disfrutar de los cambios y oportunidades, no te olvides que estamos en pleno crecimiento.",
            "Ser una persona dispuesta a proponer y mejoras procesos!, queremos aprender de vos!"
        ],
        "city": "México",
    },
    {
        "id": "6",
        "position": "Salesforce Specialist.",
        "location": "Ciudad de mexico",
        "description": `Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación. Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?`,
        "date": "28 FEB 2022",
        "responsabilities": [
            "Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación.",
            "Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?",
        ],
        "challenges": [
            "Serás owner de varios módulos de la aplicación de cara al usuario final, con   tecnologías Android, iOS, Node, Java.",
            "Esperamos que propongas diseños técnicos escalables, simples, mantenibles y resilientes.",
            "Que seas un embajador de la calidad de código y de las buenas prácticas, diriamos que es un must!",
            "Estarás junto al equipo de UX y al equipo de Producto, siendo responsable de la solución técnica de punta a punta y la integración con otros módulos.",
            "Que te sientas cómodo mostrando el impacto que genera tu equipo frente a los stakeholders de la Compañía.",
            "Y podrás armar tu propio equipo en conjunto con el equipo de Talent Acquisition."
        ],
        "requirements": [
            "Sólida experiencia Desarrollando con Java.",
            "Idealmente que hayas trabajado con microservicios.",
            "Poseer experiencia liderando equipos de alto rendimiento.",
            "Proponer Mejoras simples en IT con impacto en el negocio.",
            "Que disfrutes trabajando en equipo es clave, estamos construyendo una cultura 100% colaborativa.",
            "Disfrutar de los cambios y oportunidades, no te olvides que estamos en pleno crecimiento.",
            "Ser una persona dispuesta a proponer y mejoras procesos!, queremos aprender de vos!"
        ],
        "city": "México",
    },
    {
        "id": "7",
        "position": "Salesforce Developer.",
        "location": "Ciudad de mexico",
        "date": "28 FEB 2022",
        "description": `Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación. Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?`,
        "responsabilities": [
            "Queremos sumar personas a nuestros equipos que sean apasionados por la tecnología y la innovación.",
            "Apuntamos a personas con muchas ganas de aportar nuevas ideas, con una gran mirada analítica, que se sientan cómodos en un ambiente dinámico y en pleno crecimiento! ¿Te sumas?",
        ],
        "challenges": [
            "Serás owner de varios módulos de la aplicación de cara al usuario final, con   tecnologías Android, iOS, Node, Java.",
            "Esperamos que propongas diseños técnicos escalables, simples, mantenibles y resilientes.",
            "Que seas un embajador de la calidad de código y de las buenas prácticas, diriamos que es un must!",
            "Estarás junto al equipo de UX y al equipo de Producto, siendo responsable de la solución técnica de punta a punta y la integración con otros módulos.",
            "Que te sientas cómodo mostrando el impacto que genera tu equipo frente a los stakeholders de la Compañía.",
            "Y podrás armar tu propio equipo en conjunto con el equipo de Talent Acquisition."
        ],
        "requirements": [
            "Sólida experiencia Desarrollando con Java.",
            "Idealmente que hayas trabajado con microservicios.",
            "Poseer experiencia liderando equipos de alto rendimiento.",
            "Proponer Mejoras simples en IT con impacto en el negocio.",
            "Que disfrutes trabajando en equipo es clave, estamos construyendo una cultura 100% colaborativa.",
            "Disfrutar de los cambios y oportunidades, no te olvides que estamos en pleno crecimiento.",
            "Ser una persona dispuesta a proponer y mejoras procesos!, queremos aprender de vos!"
        ],
        "city": "México",
    }
]

export default listsJobs;