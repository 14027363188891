import { Link } from "react-router-dom";
import "./Privacy.scss";
import Download from "../../assets/Download.svg";

export const Privacy: React.FC<{}> = () => {
  return (
    <div className="container-privacy">
      <h1 className="title-privacy">Politica de Privacidad</h1>
      <p className="last-update">Última actualización: 14 de Marzo, 2023</p>

      <div className="container-service">
        <h3 className="title-service">AVISO DE PRIVACIDAD INTEGRAL</h3>
        <h3 className="title-service">GRUPO PRO CONTACTO</h3>

        <p className="info-text">
          GRUPO PRO CONTACTO 3 SERVICIOS, S.A. DE C.V. Y PRO CONTACTO3
          SERVICIOS, S.C., (en lo sucesivo “GRUPO PRO CONTACTO”), con domicilio
          en Avenida Revolución, número 779, interior 3, Colonia Nonoalco,
          Código Postal 03700, Alcaldía Benito Juárez, Ciudad de México, México
          y con el portal de internet <a href="/">www.procontacto.com.mx</a>, en
          cumplimiento con la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares (LFPDPPP),especialmente los artículos 16
          y 17, y su reglamento, es el responsable de la protección de sus datos
          personales y se compromete con usted a salvaguardar la
          confidencialidad y la privacidad de los datos personales que recabe
          por cualquier medio, sea impreso o electrónico, y a evitar que los
          mismos sufran algún daño, pérdida, extravío, destrucción o alteración,
          lo mismo que al uso no autorizado de los mismos.
        </p>
        <p className="info-text">
          Su privacidad y confianza son muy importantes para nosotros, por ello
          queremos asegurarnos de que conozca como salvaguardamos la integridad,
          privacidad y protección de los datos personales que usted nos
          proporciona.
        </p>
        <h3 className="items">1. DEL OBJETO DEL AVISO DE PRIVACIDAD</h3>
        <div>
          <p className="paragraph">
            El presente Aviso de Privacidad tiene como finalidad cumplir con lo
            establecido en los artículos 6, Apartado A y 16, segundo párrafo, de
            la Constitución Política de los Estados Unidos Mexicanos y las
            diversas disposiciones de LFPDPPP, para delimitar los alcances y
            condiciones generales del tratamiento, de modo que GRUPO PRO
            CONTACTO cuente con los datos personales de sus clientes y
            proveedores, necesarios para la realización de los fines comerciales
            y de prestación de servicios que sostengan. Asimismo, el aviso de
            privacidad permite transparentar dicho tratamiento, y con ello
            fortalecer el nivel de confianza de los titulares.
          </p>
          <p className="paragraph">
            Los datos personales que sean transferidos serán tratados
            exclusivamente para los fines y bajo los términos detallados en el
            presente aviso de privacidad, que puede ser consultado en todo
            momento en el sitio web www.procontacto.com.mx
          </p>
        </div>
        <h3 className="items">2. DE LOS DATOS PERSONALES A RECABAR</h3>
        <div>
          <p className="paragraph">
            GRUPO PRO CONTACTO recaba los datos personales que usted libre y
            voluntariamente nos proporciona para el cumplimiento de los
            servicios contratados, los cuales, de manera enunciativa, más no
            limitativa incluyen:
          </p>
          <p className="marked">Datos Personales</p>
          <ul>
            <li className="paragraph">
              Datos de identificación
              <ul className="second-ul">
                <li>Nombre completo</li>
                <li>Fecha de nacimientos</li>
                <li>Edad</li>
                <li>Nacionalidad</li>
                <li>Sexo</li>
                <li>Estado civil</li>
                <li>CURP</li>
                <li>Documento de identificación</li>
                <li>Firma</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li className="paragraph">
              Contacto
              <ul className="second-ul">
                <li>Domicilio</li>
                <li>Teléfono</li>
                <li>Correo electrónico</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li className="paragraph">
              Fiscales
              <ul className="second-ul">
                <li>Registro Federal de Contribuyentes</li>
                <li>Domicilio fiscal</li>
                <li>Número de Seguridad Social</li>
                <li>Número de infonavit</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li className="paragraph">
              Patrimoniales o Financieros
              <ul className="second-ul">
                <li>Cuenta bancaria</li>
                <li>Tarjeta bancaria</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li className="paragraph">
              Académicos
              <ul className="second-ul">
                <li>Nivel de escolaridad</li>
                <li>Título</li>
                <li>Número de cédula</li>
                <li>Otros</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li className="paragraph">
              No se recaban Datos personales sensibles, a menos que la
              naturaleza del producto y/o servicio lo requiera necesariamente.
            </li>
          </ul>

          <p className="paragraph">
            Se presume que aquella persona que proporciona sus Datos Personales
            a GRUPO PRO CONTACTO es la titular de los mismos. En caso de
            proporcionar datos personales de otros titulares de datos
            personales, al compartirlos a GRUPO PRO CONTACTO, manifiesta contar
            con el consentimiento o representación de dichos titulares para
            proporcionarnos los datos personales y reconoce su obligación de
            informar a dicho titular o titulares que ha proporcionado sus datos,
            así como de los lugares en los que se encuentra a su disposición el
            presente Aviso de Privacidad para poder llevar a cabo la prestación
            de cualquier producto o servicio requerido.
          </p>
          <p className="paragraph">
            Los Datos Personales que Usted proporcione se conservarán o
            destruirán según las necesidades de GRUPO PRO CONTACTO y una vez
            cumplidas las finalidades para las cuales fueron recabados según lo
            establecido en este Aviso, de acuerdo con la LFPDPPP y su
            Reglamento, o cuando así lo solicite el Titular de los mismos.
          </p>

          <p className="paragraph">
            La veracidad de los datos a recabar no es responsabilidad de GRUPO
            PRO CONTACTO, puesto que ésta sólo obtiene los datos mediante
            declaración bajo protesta de decir verdad del titular de los mismos.
          </p>
        </div>
        <h3 className="items">3. DE LA OBTENCIÓN DE LOS DATOS PERSONALES</h3>
        <div>
          <p className="paragraph">
            Para los fines del presente Aviso de Privacidad, los datos
            personales se pueden recabar de las siguientes maneras: cuando
            proporciona sus datos personales de forma directa o cuando una
            persona, autorizada previamente por usted, los proporciona a GRUPO
            PRO CONTACTO, para fines permitidos.
          </p>
          <p className="paragraph">
            Los datos pueden ser recabados de forma escrita, física, verbal, por
            medios electrónicos, ópticos, sonoros, visuales o cualquier otro
            medio conocido o por conocer.
          </p>
          <p className="paragraph">
            Los datos personales serán suministrados por los titulares y
            recabados por GRUPO PRO CONTACTO, de conformidad con la ley, con el
            carácter de “Datos Personales” se transferirá utilizando tanto
            medios electrónicos, como físicos o impresos, sin constituir de
            manera alguna, medios engañosos o fraudulentos, de conformidad con
            el artículo 7 de la LFPDPPP.
          </p>
        </div>
        <h3 className="items">4. DEL USO DE LOS DATOS PERSONALES</h3>
        <div>
          <p className="paragraph">
            En cumplimiento del artículo 6 de la LFPDPPP, el tratamiento de la
            información recabada observa los principios de licitud,
            consentimiento, información, calidad, finalidad, lealtad,
            proporcionalidad y responsabilidad.
          </p>
          <p className="paragraph">
            GRUPO PRO CONTACTO utiliza los datos para las siguientes finalidades
            que son necesarias para el servicio que solicita:
          </p>
          <ul>
            <li className="paragraph">Verificar y confirmar su identidad.</li>
            <li className="paragraph">
              Evaluar la petición u oferta presentada por usted.
            </li>
            <li className="paragraph">
              Realizar el pago y/o cobro de los productos y servicios
              correspondientes.
            </li>
            <li className="paragraph">
              Realizar encuestas de satisfacción y respaldar el cumplimiento de
              las obligaciones del responsable.
            </li>
            <li className="paragraph">
              Aquellas actividades requeridas para llevar a cabo el objeto de
              los servicios prestados.
            </li>
          </ul>
          <p className="paragraph">
            El tratamiento de los datos proporcionados por usted al responsable
            se limitará al cumplimiento de la finalidad prevista en el presente
            aviso y a fines distintos que resulten compatibles o análogos a los
            establecidos en dicho aviso, sin que para ello se requiera obtener
            nuevamente consentimiento.
          </p>
          <p className="paragraph">
            Al ponerse a disposición de usted el presente aviso y no manifestar
            oposición alguna, se entenderá, de conformidad con el artículo 8 de
            la ley, que usted otorga a GRUPO PRO CONTACTO su consentimiento para
            llevar a cabo el tratamiento de los datos que hubiera proporcionado.
          </p>
          <p className="paragraph">
            De manera adicional, utilizaremos su información personal para las
            siguientes finalidades que no son necesarias para el servicio
            solicitado, pero que nos permiten y facilitan brindarle una mejor
            atención:
          </p>
          <ul>
            <li>Contactarse con usted.</li>
            <li>
              Enviarle noticias e información sobre productos y servicios.
            </li>
          </ul>
          <p className="paragraph">
            Cuando comparta sus datos, usted puede manifestar su negativa a que
            estos sean utilizados para fines no necesarios para el servicio
            solicitado.
          </p>
          <p className="paragraph">
            No será necesario el consentimiento expreso para el tratamiento de
            datos cuando se tenga el propósito de cumplir obligaciones derivadas
            de una relación jurídica entre usted y el responsable ni en los
            demás casos que contempla el artículo 10 de la LFPDPPP, lo anterior
            sin perjuicio de la facultad que posee usted para el ejercicio de
            los derechos ARCO.
          </p>
          <p className="paragraph">
            El responsable informa que toda comunicación por correo electrónico
            no protegida realizada a través de internet puede ser objeto de
            intercepción, sustracción, pérdida o posibles alteraciones en cuyo
            caso, usted no podrá exigir al responsable alguna indemnización por
            cualquier daño resultante por la intercepción sustracción pérdida o
            alteración relacionada con un mensaje de correo electrónico entre
            las partes.
          </p>
        </div>
        <h3 className="items">5. DE LA TRANSFERENCIA DE DATOS PERSONALES</h3>
        <div>
          <p className="paragraph">
            En términos de los artículos 16 fracción V y 36 de la LFPDPPP y 68
            de su reglamento, sus datos personales podrán ser compartidos a
            terceros nacionales y extranjeros con los que tengamos alguna
            relación para llevar a cabo la prestación de productos y servicios,
            incluyendo sin limitar: afiliadas, subsidiarias, controladas y
            controladoras, partes relacionadas, filiales, instituciones
            bancarias, Secretaría de Hacienda, servicios de paquetería,
            servicios de correo electrónico o newsletter y servicios de
            video/telecomunicación, incluyendo sin limitar Zoom. Al
            proporcionarnos sus datos personales por medio físico o a través de
            nuestro sitio web, usted acepta que puedan ser transferidos a efecto
            de que GRUPO PRO CONTACTO cumpla con sus fines. Los datos solamente
            serán compartidos para los fines y propósitos establecidos en el
            presente Aviso de Privacidad.
          </p>
        </div>
        <h3 className="items">
          6. DE LOS DERECHOS ARCO (acceso, rectificación, cancelación u
          oposición)
        </h3>
        <div>
          <p className="paragraph">
            Usted tendrá en todo momento y de conformidad con la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares, el
            derecho de solicitar acceder, rectificar, cancelar u oponerse al
            tratamiento de sus datos personales. La solicitud de cualquiera de
            esos derechos deberá dirigirla al correo{" "}
            <a href="mailto:administracion@procontacto.com">
              administracion@procontacto.com
            </a>
            , por el titular de los datos personales o por su representante
            legal.
          </p>
          <p className="paragraph">
            La solicitud deberá contener el nombre del titular de los datos
            personales, su domicilio y correo electrónico u otro medio para
            comunicarle la respuesta a la petición; adjuntar los documentos que
            acrediten su identidad y personalidad jurídica; la descripción clara
            y precisa sobre los datos respecto de los que se busca ejercer
            alguno de los derechos antes mencionados; así como cualquier otro
            elemento o documento que facilite la localización de los datos
            personales.
          </p>
          <p className="paragraph">
            De conformidad con la LFPDPPP, recibirá respuesta a su solicitud en
            un plazo máximo de 20 (veinte) días hábiles posteriores a la
            recepción de su solicitud, de proceder, se hará efectiva dentro de
            los 15 (quince) días hábiles siguientes a que procedió.
          </p>
          <p className="paragraph">
            La entrega de datos, al ejercitar el derecho de acceso será
            gratuita. El titular será responsable de cubrir solo los gastos de
            envío o el costo de reproducción, copias y formatos, cuando solicite
            la información en medios o formatos físicos.
          </p>
        </div>
        <h3 className="items">7. DE LA REVOCACIÓN</h3>
        <div>
          <p className="paragraph">
            En caso de revocación del consentimiento parcial o total de la
            transferencia y/o uso de datos personales, se deberá manifestar por
            escrito a GRUPO PRO CONTACTO tal deseo del titular, exponiendo los
            motivos de ello y enviarlo al correo{" "}
            <a href="mailto:administracion@procontacto.com">
              administracion@procontacto.com
            </a>{" "}
            en el entendido que no se le atribuirá efectos retroactivos a dicha
            revocación, como se establece en el artículo 8 de la LFPDPPP.
          </p>
          <p className="paragraph">
            La solicitud deberá contener el nombre del titular de los datos
            personales, su domicilio y correo electrónico u otro medio para
            comunicarle la respuesta a la petición; adjuntar los documentos que
            acrediten su identidad o personalidad jurídica; y la descripción
            clara y precisa sobre los datos respecto de los que se busca ejercer
            la revocación; así como cualquier otro elemento o documento que
            facilite la localización de los datos personales.
          </p>
          <p className="paragraph">
            De conformidad con la LFPDPPP, recibirá respuesta a su solicitud en
            un plazo máximo de 20 (veinte) días hábiles posteriores a la
            recepción de su solicitud, de proceder, se hará efectiva dentro de
            los 15 (quince) días hábiles siguientes a que procedió.
          </p>
          <p className="paragraph">
            Asimismo, GRUPO PRO CONTACTO se reserva el derecho de exigir la
            cancelación de datos personales sensibles de los recursos humanos a
            su cargo y que se encuentren en posesión de sus clientes o
            proveedores.
          </p>
        </div>
        <h3 className="items">8. DE LOS CAMBIOS AL AVISO DE PRIVACIDAD</h3>
        <div>
          <p className="paragraph">
            GRUPO PRO CONTACTO se reserva el derecho de hacer cambios y
            actualizaciones a este Aviso en cualquier momento. En caso de
            existir alguna modificación se hará de su conocimiento a través de
            nuestro portal de internet www.procontacto.com.mx, el cual siempre
            contendrá la última versión del Aviso de Privacidad.
          </p>
          <p className="paragraph">
            Toda consulta, duda o aclaración por parte de los clientes respecto
            de este Aviso de Privacidad, deberá enviarse al correo{" "}
            <a href="mailto:administracion@procontacto.com">
              administracion@procontacto.com .
            </a>
          </p>
        </div>
        <h3 className="items">9. MANIFESTACIÓN DE LA VOLUNTAD</h3>
        <div>
          <p className="paragraph">
            Usted tiene pleno control y decisión de sus datos personales, es por
            ello que usted, al proporcionar sus Datos Personales de manera
            personal o a través de cualquier otro medio electrónico, óptico,
            sonoro, visual o a través de cualquier otra tecnología y de otras
            fuentes permitidas por la LFPDPPP, así como al hacer uso de los
            productos y servicios que GRUPO PRO CONTACTO presta, confirma y
            acepta que ha leído y otorga su consentimiento expreso para que sus
            Datos Personales sean tratados de acuerdo con los Términos y
            Condiciones del presente Aviso de Privacidad.
          </p>
          <p className="paragraph">
            Al no existir manifestación de oposición alguna por parte de Usted
            respecto al presente Aviso de Privacidad, se entenderá que Usted
            otorga a GRUPO PRO CONTACTO su consentimiento para que este último
            recabe, utilice, trate y transfiera sus Datos Personales para los
            fines descritos en el presente Aviso de Privacidad, cumpliendo con
            los términos de la LFPDPPP y su Reglamento.
          </p>
          <p className="paragraph">
            Este Aviso de Privacidad se aplica a todos los usuarios de los
            servicios de <a href="/">www.procontacto.com.mx</a> en México,
            incluidas las redes sociales:{" "}
            <a className="linkedin" href="https://www.linkedin.com/company/grupoprocontacto/">
              https://www.linkedin.com/company/grupoprocontacto/
            </a>
          </p>
        </div>
        <h3 className="items">10. MECANISMOS PARA MANIFESTAR NEGATIVA</h3>
        <div className="last-item">
          <p className="paragraph">
            Usted en todo momento puede expresar la negativa para proporcionar o
            en su caso permitir el tratamiento de datos personales, haciéndolo
            saber por escrito al correo administracion@procontacto.com
          </p>
          <p className="paragraph">
            La Fecha de la última actualización a este Aviso de Privacidad es
            del 14 de marzo de 2023.
          </p>
        </div>

        <h4 className="format">
          Formato para ejercer Derechos ARCO.
          <br />
          Protección de Datos Personales en Posesión de Particulares
        </h4>
        <div className="section-download">
          <p>
            GRUPO PRO CONTACTO 3 SERVICIOS, S.A. DE C.V.{" "}
            <Link
              to="/files/FORMATO DERECHOS ARCO PRO CONTACTO.docx"
              target="_blank"
              download
            >
              DESCARGAR <img src={Download} />
            </Link>
          </p>
          <p className="paragraph">
            PRO CONTACTO3 SERVICIOS, S.C{" "}
            <Link
              to="/files/FORMATO DERECHOS ARCO GRUPO PRO.docx"
              target="_blank"
              download
            >
              DESCARGAR <img src={Download} />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
