import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { Salesforce } from "../components/Salesforce/Salesforce";

export const SalesforceConsultant: React.FC<{}> = () => {
  return (
    <>
      <Header />
      <Salesforce />
      <Footer />
    </>
  );
};
