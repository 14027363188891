import './Info.scss'
import Stonks from '../../assets/card-icon/serviciosComerciales.gif'
import Code from '../../assets/card-icon/software-info.gif'
import Salesforce from '../../assets/card-icon/salesforce-info.gif'
import ArrowRigth from "../../assets/card-icon/arrow-right.svg"
import Gif from '../../assets/GIFHome.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'
import { Link } from 'react-router-dom'


export const Info: React.FC<{}> = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  return (
    <div className="info-container" id="section2">
      {/* <Animation /> */}
      <div className="gif-wrapper">
        <img className="gif-info" src={Gif}></img>
      </div>
      <div className="text-container" data-aos="fade-up" data-aos-delay="300" data-aos-once="true" data-aos-duration="2000">
        <h2>
          Optimiza <span id="span">tus </span>procesos con nuestra ayuda.
        </h2>
        <h3>
          Implementamos soluciones a medida que impactan directamente en
          procesos claves del negocio asegurando el éxito.
        </h3>
      </div>
      <div className="info-card-container">
        <div className="card-container" data-aos="fade-up" data-aos-delay="500" data-aos-once="true" data-aos-duration="1500">
          <div className='body-container'>
            <div className='car-img-container'>
              <img className='card-icon' src={Stonks} />
            </div>
            <h2>Servicios comerciales</h2>
            <h3>Coordinamos equipos de fuerza de venta especializados en soluciones de cobro con tarjetas y visitas de servicio a redes comerciales.</h3>
          </div>
          <div className='more-info'>
            <a href="/commercial-services" className='link-info-card'>
              MÁS INFORMACIÓN
              <img className='img-arrow' src={ArrowRigth} />
            </a>
          </div>
        </div>

        <div className="card-container" data-aos="fade-up" data-aos-delay="650" data-aos-once="true" data-aos-duration="1500">
          <div className='body-container'>
            <div className='car-img-container'>
              <img className='card-icon' src={Code} />
            </div>
            <h2>Software factory</h2>
            <h3>Desarrollamos soluciones tecnológicas mediante procesos con eficacia comprobada.</h3>
          </div>
          <div className='more-info'>
            <a className='link-info-card'>MÁS INFORMACIÓN </a>
            <img src={ArrowRigth} />
          </div>
        </div>

        <div className="card-container" data-aos="fade-up" data-aos-delay="850" data-aos-once="true" data-aos-duration="1500">
          <div className='body-container'>
            <div className='car-img-container'>
              <img className='card-icon' src={Salesforce} />
            </div>
            <h2>Especialistas en Salesforce</h2>
            <h3>Brindamos consultoría, implementación y capacitación en Salesforce.</h3>
          </div>
          <div className='more-info'>
            <a href='/salesforce' className='link-info-card'>
              MÁS INFORMACIÓN
              <img className='img-arrow' src={ArrowRigth} />
            </a>
          </div>
        </div>
        {/*<Card
          title="Servicios comerciales"
          body="Coordinamos equipos de fuerza de venta especializados en soluciones de cobro con tarjetas y visitas de servicio a redes comerciales."
          src={Stonks}
          moreInfo=""
        />
        <Card
          title="Software factory"
          body="Desarrollamos soluciones tecnológicas mediante procesos con eficacia comprobada."
          src={Code}
          moreInfo=""
        />
        <Card
          title="Especialistas en Salesforce"
          body="Brindamos consultoría, implementación y capacitación en Salesforce."
          src={Salesforce}
          moreInfo=""
  />*/}
      </div>
    </div>
  )
}
