import "./About.scss";
import AboutImage from "../../assets/AboutImage.svg";
import data from './aboutData'

export const AboutComponent: React.FC<{}> = () => {
  return (
    <div className="container-about">
      <div className="section-title">
        <h3 className="subtitle-services">ACERCA DE</h3>
        <h1 className="title-services">
          Creamos soluciones innovadoras para tu negocio combinando diseño y
          tecnología.
        </h1>
        <div className="image-services">
          <img src={AboutImage} />
        </div>
      </div>

      <div className="section-cards">
        <h1 className="title-cards">Código y competencias humanas<br></br><span>en sintonía</span></h1>
        <div className="section-card">
          {data.map((item) => {
            return (
              <div className="card" key={item.id}>
                <div className="section-image">
                  <img src={item.image} />
                </div>
                <div className="section-information">
                  <h3 className="title">{item.title}</h3>
                  <p className="information">
                    {item.information}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};
