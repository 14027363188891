import './App.scss';
import { Home } from './pages/Home';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Error } from './components/Error/Error';
import { CommercialServices } from './pages/CommercialServices';
import { SalesforceConsultant } from './pages/Salesforce';
import { Job } from './pages/Job';
import { JobDetails } from './pages/JobDetails';
import { Routes, Route } from 'react-router-dom';
import { About } from './pages/About';
import { Software } from './pages/Software';

function App() {


  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Error/>} />
        <Route path="/commercial-services" element={<CommercialServices/>} />
        <Route path="/salesforce" element={<SalesforceConsultant/>} />
        <Route path="/job" element={<Job/>} />
        <Route path='/job/:id' element={<JobDetails/>} />
        <Route path="/about" element={<About />} />
        <Route path="/software-factory" element={<Software />} />
      </Routes>
  );
}

export default App;
