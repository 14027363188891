import "../InfoServices/InformationServices.scss";
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import Job from "../../assets/marketing/Job.svg";
import Service from "../../assets/marketing/Service.svg";
import Business from "../../assets/marketing/Business.svg";
import Hands from "../../assets/marketing/Hands.svg";
import Briefcase from "../../assets/marketing/Briefcase.svg";
import Handset from "../../assets/marketing/Handset.svg";
import ArrowRightDisable from "../../assets/ArrowRightDisable.svg";
import ArrowRight from "../../assets/ArrowRight.svg";
import ArrowLeft from "../../assets/ArrowLeft.svg";
import { useState } from "react";


export const CarouselMobile: React.FC<{}> = () => {
    const [ventas, setVentas] = useState<Boolean>(true);
    const [service, setService] = useState<Boolean>(false);
    const [other, setOther] = useState<Boolean>(false);
    const [width, setWidth] = useState(window.innerWidth);

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
    });

    
    const handleClickVentas = () => {
        const sliderVentas = document.querySelector(".slider-sales-mobile");
        const sliderService = document.querySelector(".slider-service-mobile");
        const sliderOther = document.querySelector(".slider-other-mobile");
        const buttonSales = document.querySelector(".button-sales-mobile");
        const buttonService = document.querySelector(".button-service-mobile");
        const buttonOther = document.querySelector(".button-other-mobile");

        sliderVentas?.classList.remove("hidden-mobile");
        sliderService?.classList.add("hidden-mobile");
        sliderOther?.classList.add("hidden-mobile");

        buttonSales?.classList.add("button-select-mobile");
        buttonService?.classList.remove("button-select-mobile");
        buttonOther?.classList.remove("button-select-mobile");

        setVentas(true);
        setService(false);
        setOther(false);
    }

    const handleClickService = () => {
        const sliderService = document.querySelector(".slider-service-mobile");
        const sliderVentas = document.querySelector(".slider-sales-mobile");
        const sliderOther = document.querySelector(".slider-other-mobile");
        const buttonSales = document.querySelector(".button-sales-mobile");
        const buttonService = document.querySelector(".button-service-mobile");
        const buttonOther = document.querySelector(".button-other-mobile");

        sliderService?.classList.remove("hidden-mobile");
        sliderVentas?.classList.add("hidden-mobile");
        sliderOther?.classList.add("hidden-mobile");

        buttonService?.classList.add("button-select-mobile");
        buttonSales?.classList.remove("button-select-mobile");
        buttonOther?.classList.remove("button-select-mobile");

        setVentas(false);
        setOther(false);
        setService(true);
    }

    const handleClickOther = () => {
        const sliderService = document.querySelector(".slider-service-mobile");
        const sliderVentas = document.querySelector(".slider-sales-mobile");
        const sliderOther = document.querySelector(".slider-other-mobile");
        const buttonSales = document.querySelector(".button-sales-mobile");
        const buttonService = document.querySelector(".button-service-mobile");
        const buttonOther = document.querySelector(".button-other-mobile");

        sliderOther?.classList.remove("hidden-mobile");
        sliderService?.classList.add("hidden-mobile");
        sliderVentas?.classList.add("hidden-mobile");

        buttonOther?.classList.add("button-select-mobile");
        buttonService?.classList.remove("button-select-mobile");
        buttonSales?.classList.remove("button-select-mobile");

        setVentas(false);
        setService(false);
        setOther(true);
    }

    return(
        <div>
            <CarouselProvider
                naturalSlideWidth={90}
                naturalSlideHeight={width > 535 ? 70 : 100}
                totalSlides={ventas ? 6 : service ? 5 : 7}
                visibleSlides={1}
            >
                <div className="sectionCarousel" id="sectionCarousel">
                    <div className="containerCarousel">
                        <div className="container">
                            <button 
                                className="button-sales-mobile button-select-mobile"
                                onClick={handleClickVentas} 
                            >
                                <span className="variant">Fuerza de ventas</span>
                            </button>
                            <button
                                className="button-service-mobile"
                                onClick={handleClickService}
                            >
                                <span className="variant">Visitas de servicio</span>
                            </button>
                            <button 
                                className="button-other-mobile"
                                onClick={handleClickOther}
                            >
                                <span className="variant">Otros servicios</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="section-sliders">
                    <Slider className="slider-sales-mobile">
                        <Slide index={0}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Job} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Creación y coordinación de equipos de ventas</h3>
                                    <p className="text-slide">Armamos equipos a medida que se ajustan a tu necesidad.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Service} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Afiliación de comercios a redes de terminales punto de venta</h3>
                                    <p className="text-slide">Facilitamos el procesos de afiliación de manera presencial y telefónica.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={2}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Business} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Introducción de servicios en establecimientos comerciales</h3>
                                    <p className="text-slide">Ofrecemos nuevos servicios a negocios ya afiliados.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={3}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Hands} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Negociaciones de alto nivel</h3>
                                    <p className="text-slide">Representantes comerciales para negociar con directores o gerentes.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={4}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Briefcase} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Equipos de cambaceo</h3>
                                    <p className="text-slide">Nuestro equipo visita negocios para ofrecer tus servicios.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={5}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Handset} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Comercialización vía telemarketing</h3>
                                    <p className="text-slide">Callcenter propio para comercializar tus servicios.</p>
                                </div>
                            </div>
                        </Slide>
                    </Slider>
                    <Slider className="slider-service-mobile hidden-mobile">
                        <Slide index={0}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Job} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Encuestas y evaluaciones de servicio presenciales</h3>
                                    <p className="text-slide">Visitamos los negocios afiliados para relevar cómo están utilizando tus servicios.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Service} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Soporte primer nivel a terminales punto de venta</h3>
                                    <p className="text-slide">Nuestro equipo de visitas se encarga del primer nivel de soporte a los negocios.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={2}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Business} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Señalización de comercios con material POP</h3>
                                    <p className="text-slide">Señalizamos los comercios con diferentes materiales de tu marca.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={3}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Hands} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Generación de prospectos en campo</h3>
                                    <p className="text-slide">Relevamos candidatos para subir la eficiencia de tu equipo de ventas.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={4}>
                            <div className="container-slide">
                                <div className="container-image">
                                    <img src={Briefcase} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Entrega de rollos para terminales punto de venta</h3>
                                    <p className="text-slide">Abastecemos de insumos a los comercios adheridos.</p>
                                </div>
                            </div>
                        </Slide>
                    </Slider>

                    <Slider className="slider-other-mobile hidden-mobile">
                        <Slide index={0}>
                            <div className="container-slide-other">
                                <div className="container-image">
                                    <img src={Job} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Auditoría de productos y servicios en punto de venta</h3>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1}>
                            <div className="container-slide-other">
                                <div className="container-image">
                                    <img src={Service} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Mesas de ayuda</h3>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={2}>
                            <div className="container-slide-other">
                                <div className="container-image">
                                    <img src={Business} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Soporte y atención al cliente</h3>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={3}>
                            <div className="container-slide-other">
                                <div className="container-image">
                                    <img src={Hands} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Campañas telemarketing</h3>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={4}>
                            <div className="container-slide-other">
                                <div className="container-image">
                                    <img src={Briefcase} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Cierre de ventas con cargo a tarjetas de crédito y débito</h3>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={5}>
                            <div className="container-slide-other">
                                <div className="container-image">
                                    <img src={Briefcase} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Geolocalización de los puntos de venta.</h3>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={6}>
                            <div className="container-slide-other">
                                <div className="container-image">
                                    <img src={Briefcase} />
                                </div>
                                <div className="container-text">
                                    <h3 className="title-slide">Instalación y recuperación de terminales punto de venta</h3>
                                </div>
                            </div>
                        </Slide>
                    </Slider>
                </div>
            </CarouselProvider>

        </div>
    )
}