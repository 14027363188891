import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import { Details } from "../components/Work/JobDetails/Details";

export const JobDetails: React.FC<{}> = () => {
  return (
    <>
      <Header />
      <Details />
      <Footer />
    </>
  );
};
