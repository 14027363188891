import { useState, useEffect } from "react";
import "./Header.scss";
import SelectDown from "../../assets/select-down.svg";
import MenuImage from "../../assets/menu.svg";
import MenuClose from "../../assets/CloseMenu.svg";
import { slide as Menu } from "react-burger-menu";
import Logo from "../../assets/logo.svg";
import { Dropdown } from "react-bootstrap";
import Modal from "../modalContact/fullModal";
import { Contact } from "../Contact/Contact";


export const Header: React.FC<{}> = ({}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [active, setActive] = useState({
    esp: true,
    eng: false,
  });
  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = () => {
    //si el modal esta abierto no poder hacer scroll
    if (!showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    setShowModal(!showModal);
  };

  const toBackHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="header-container">
      <div className="logo-container">
        <a className="link-home" onClick={toBackHome}>
          <img className="logo" src={Logo} />
          <div className="name-container">
            <p className="first">Pro</p>
            <p className="second">Contacto</p>
          </div>
        </a>
      </div>
      <div className="container-desktop">
        <div className="section-menu-desktop">
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
          >
            <Dropdown.Toggle className="main-style" id="dropdown-basic">
              <span>
                Servicios <img className="menu" src={SelectDown} />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item href="/commercial-services">
                SERVICIOS COMERCIALES
              </Dropdown.Item>
              <Dropdown.Item href="/software-factory">SOFTWARE FACTORY</Dropdown.Item>
              <Dropdown.Item href="/salesforce">
                ESPECIALISTAS EN SALESFORCE
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <a href="/about">ACERCA DE</a>
          <button className="contact" onClick={handleModalToggle}>
            CONTACTO
          </button>
          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={handleModalToggle}
            >
            </Modal>
          )}
          <a href="/job">UNIRME AL EQUIPO</a>

          <select className="select-language" defaultValue={'es'}>
            <option value="en">ENG</option>
            <option value="es">
              ESP
            </option>
          </select>
        </div>
      </div>
      <div className="container-mobile">
        <Menu
          //colocarlo a la izquierda
          right
          width={"100%"}
          customBurgerIcon={
            <button className="menu-button">
              <img src={MenuImage} />
            </button>
          }
          //cambiar el icono de close menu
          customCrossIcon={
            <button className="menu-close">
              <img src={MenuClose} />
            </button>
          }
        >
          <Dropdown>
            <Dropdown.Toggle className="main-style" id="dropdown-basic">
              <span>Servicios</span>
              <img className="menu" src={SelectDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item href="/commercial-services">
                SERVICIOS COMERCIALES
              </Dropdown.Item>
              <Dropdown.Item href="/software-factory">SOFTWARE FACTORY</Dropdown.Item>
              <Dropdown.Item href="/salesforce">
                ESPECIALISTAS EN SALESFORCE
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <a className="menu-item" href="/about">
            ACERCA DE
          </a>
          <button className="contact-mobile" onClick={handleModalToggle}>
            CONTACTO
          </button>
          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={handleModalToggle}
            >
            </Modal>
          )}
          <a className="menu-item" href="/job">
            UNIRME AL EQUIPO
          </a>

          <div className="select-language">
            <button
              className={active.esp ? "select-button active" : "select-button"}
              onClick={() => {
                setActive({
                  esp: true,
                  eng: false,
                });
              }}
            >
              ESP
            </button>
            <button
              className={active.eng ? "select-button active" : "select-button"}
              onClick={() => {
                setActive({
                  esp: false,
                  eng: true,
                });
              }}
            >
              ENG
            </button>
          </div>
        </Menu>
      </div>
    </div>
  );
};
